/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  En dialogruta som kan användas för att ge användaren generell information.

  Props:
  -title (string)
  -message (string)
  -buttons (array av knappar)
  -Dialogs (Dialogs.js context)
*/

import React, { Component } from 'react';

import Modal from './../Modal.js';
import Button from './../Button.js';

export default class EditCompany extends Component {
  onClick(button){
    this.props.App.Dialogs.hide()
    .then(() => {
      if(typeof button.onClick === "function"){
        button.onClick();
      }
    });
  }

  render(){
    return (
      <Modal width={400}>
        <h3>{this.props.title}</h3>
        <NewlineText text={this.props.message} />
        {
          this.props.buttons !== null ?
          this.props.buttons.map((button, key) => <Button key={key} text={button.text} onClick={() => this.onClick(button)}/>)
          :
          <Button text="OK" onClick={() => this.props.Dialogs.hide()} style={{marginTop: 5}}/>
        }
      </Modal>
    );
  }
}

// https://forum.freecodecamp.org/t/newline-in-react-string-solved/68484
function NewlineText(props) {
  const text = props.text;
  if (text != null) {
    const newText = text.split('\n').map(str => <p key={str} >{str}</p>);
    return newText;
  } else {
    return <p></p>;
  }
}