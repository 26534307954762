/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  En dialogruta för redigering av det aktiva företaget.

  Props:
  -Dialogs (Dialogs.js context)
  -App (App.js context)
  -company (object)
*/

import React, { Component } from 'react';

import api from './../../api.js';

import Modal from './../Modal.js';
import Button from './../Button.js';
import Checkbox from './../Checkbox.js';
import {
  nonNullString
} from '../../utils.js';

const initialState = {
  error: null,
  loading: false,
  submittable: false,
  emailInvoice: "",
  visitingStreet: "",
  street: "",
  postalCode: "",
  municipality: "",
  invoiceMarker: "",
  allowRegChangeOnMobile: false
};

export default class EditCompany extends Component {
  constructor(props){
    super(props);

    this.state = initialState;
  }

  componentDidMount(){
    this.setState({
      emailInvoice: nonNullString(this.props.company.emailInvoice),
      visitingStreet: nonNullString(this.props.company.address.visitingStreet),
      street: nonNullString(this.props.company.address.street),
      postalCode: nonNullString(this.props.company.address.postalCode),
      municipality: nonNullString(this.props.company.address.municipality),
      invoiceMarker: nonNullString(this.props.company.invoiceMarker),
      allowRegChangeOnMobile: this.props.company.allowRegChangeOnMobile
    }, () => this.submittable());
  }

  submittable(){
    if(this.state.street !== "" && this.state.postalCode !== "" && this.state.municipality !== "" && (this.state.street !== nonNullString(this.props.company.address.street) || this.state.postalCode !== nonNullString(this.props.company.address.postalCode) || this.state.municipality !== nonNullString(this.props.company.address.municipality) || this.state.visitingStreet !== nonNullString(this.props.company.address.visitingStreet) || this.state.emailInvoice !== nonNullString(this.props.company.emailInvoice) || this.state.invoiceMarker !== nonNullString(this.props.company.invoiceMarker) || this.state.allowRegChangeOnMobile !== this.props.company.allowRegChangeOnMobile)){
      this.setState({
        submittable: true
      });
    }
    else{
      this.setState({
        submittable: false
      });
    }
  }

  onChange(val){
    this.setState(val, () => this.submittable());
  }

  submit(){
    if(this.state.submittable){
      this.setState({
        loading: true
      });

      api.fetch("edit-company", {
        id: this.props.company.id,
        emailInvoice: this.state.emailInvoice,
        visitingStreet: this.state.visitingStreet,
        street: this.state.street,
        postalCode: this.state.postalCode,
        municipality: this.state.municipality,
        invoiceMarker: this.state.invoiceMarker,
        allowRegChangeOnMobile: this.state.allowRegChangeOnMobile ? "Ja" : "Nej"
      })
      .then((data) => {
        if(data.status === "OK"){
          this.props.App.updateAll()
          .then(() => {
            this.props.App.Dialogs.hide();
          });
        }
        else{
          this.setState({
            loading: false,
            error: data.error
          });
        }
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          loading: false,
          error: "Någonting gick fel. Vänligen försök igen senare och kontakta oss om problemet kvarstår."
        });
      });
    }
  }

  render(){
    return (
      <Modal width={400}>
        <h3>Redigera {this.props.company.name}</h3>
        <p>Obligatoriska fält är markerade med<span className="red">*</span></p>
        <p className="modalError">{this.state.error}</p>
        <p>E-postadress för faktura</p>
        <input className="withLabel" type="email" name="emailInvoice" value={this.state.emailInvoice} onChange={(event) => this.onChange({emailInvoice: event.target.value})}/>
        <p>Besöksadress</p>
        <input className="withLabel" type="text" name="visitingStreet" value={this.state.visitingStreet} onChange={(event) => this.onChange({visitingStreet: event.target.value})}/>
        <p>Fakturaadress<span className="red">*</span></p>
        <input className="withLabel" type="text" name="street" value={this.state.street} onChange={(event) => this.onChange({street: event.target.value})}/>
        <p>Postnummer<span className="red">*</span></p>
        <input className="withLabel" type="text" name="postalCode" value={this.state.postalCode} onChange={(event) => this.onChange({postalCode: event.target.value})}/>
        <p>Ort<span className="red">*</span></p>
        <input className="withLabel" type="text" name="municipality" value={this.state.municipality} onChange={(event) => this.onChange({municipality: event.target.value})}/>
        <p>Fakturamärkning</p>
        <input className="withLabel" type="text" name="invoiceMarker" value={this.state.invoiceMarker} onChange={(event) => this.onChange({invoiceMarker: event.target.value})}/>
        <Checkbox checked={this.state.allowRegChangeOnMobile} label="Tillåt förare att byta reg.nr" onClick={() => this.onChange({allowRegChangeOnMobile: !this.state.allowRegChangeOnMobile})}/>

        <Button loading={this.state.loading} disabled={!this.state.submittable} text="Spara" onClick={() => this.submit()} style={{marginTop: 5}}/>
        <Button text="Avbryt" onClick={() => this.props.Dialogs.hide()} style={{marginTop: 5}}/>
      </Modal>
    );
  }
}
