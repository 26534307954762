/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Header.

  Props:
  -App (App.js context)
  -fixed (bool)
*/

import React, { Component } from 'react';

import "./../styles/Header.min.css";

import Images from './../Images.js';

export default class Header extends Component {
  render(){
    return (
      <div id="Header" className={this.props.fixed ? "fixed" : ""}>
        <img className="logo" src={Images.Logo} alt=""/>
      </div>
    );
  }
}
