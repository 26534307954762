/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  En dialogruta för att lägga till en bil på det aktiva företaget.

  Props:
  -Dialogs (Dialogs.js context)
  -App (App.js context)
*/

import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import api from './../../api.js';

import Modal from './../Modal.js';
import Button from './../Button.js';
import Checkbox from './../Checkbox.js';
import {
  truncateInternalCleanName
} from '../../utils.js';

import "./../../styles/Select.min.css";

export default class CreateCar extends Component {
  constructor(props){
    super(props);

    this.state = {
      submittable: false,
      reg: "",
      realReg: "",
      firstname: "",
      lastname: "",
      phone: "",
      stripeServiceDate: "",
      hasCustomReg: false,
      hasInternalClean: false,
      internalCleanId: "",
      region: this.props.App.state.companies[this.props.App.state.activeCompanyIndex].region,
      hasRegion: false,
    };

    if (this.state.region !== "") {
      this.state.hasRegion = true;
    }
  }

  submittable(){
    var submittable = false;
    if (this.submittableStripeService() && this.submittableReg() && this.submittableInternalClean()){
      submittable = true
    }
    this.setState({submittable});
  }

  submittableStripeService() {
    if (this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService) {
      return !(this.state.stripeServiceDate === "" || this.state.stripeServiceDate === null)
    } else  {
      return (this.state.stripeServiceDate === "" || this.state.stripeServiceDate === null)
    }
  }

  submittableReg() {
    if (this.state.reg === "") {
      return false
    }
    if (this.state.hasCustomReg) {
      return this.state.realReg !== ""
    }
    return true
  }

  submittableInternalClean() {
    if (this.state.hasInternalClean) {
      return this.state.internalCleanId !== ""
    }
    return true
  }

  onChange(val){
    this.setState(val, () => this.submittable());
  }

  submit(){
    if(this.state.submittable){
      this.setState({
        loading: true
      });

      if(this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService) {
        api.fetch("create-car", {
          companyId: this.props.App.state.companies[this.props.App.state.activeCompanyIndex].id,
          reg: this.state.reg,
          realReg: this.state.realReg,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          phone: this.state.phone,
          stripeServiceDate: moment(this.state.stripeServiceDate).format("yyyy-MM-DD"),
          internalCleanId: this.state.hasInternalClean ? this.state.internalCleanId : "",
          internalCleanIncluded: (this.state.hasInternalClean && this.state.internalCleanId !== ""),
        })
        .then((data) => {
          if(data.status === "OK"){
            this.props.App.Cars.update()
            .then(() => {
              this.props.Dialogs.hide()
              .then(() => {
                if(data.error !== undefined){
                  this.props.Dialogs.alert("Någonting gick fel", data.error);
                }
              });
            });
          }
          else{
            this.setState({
              loading: false,
              error: data.error
            });
          }
        })
        .catch((e) => {
          this.setState({
            loading: false,
            error: e.message
          });
        });
    } else {
      api.fetch("create-car", {
        companyId: this.props.App.state.companies[this.props.App.state.activeCompanyIndex].id,
        reg: this.state.reg,
        realReg: this.state.realReg,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        phone: this.state.phone,
        internalCleanId: this.state.hasInternalClean ? this.state.internalCleanId : "",
        internalCleanIncluded: (this.state.hasInternalClean && this.state.internalCleanId !== ""),
      })
      .then((data) => {
        if(data.status === "OK"){
          this.props.App.Cars.update()
          .then(() => {
            this.props.Dialogs.hide()
            .then(() => {
              if(data.error !== undefined){
                this.props.Dialogs.alert("Någonting gick fel", data.error);
              }
            });
          });
        }
        else{
          this.setState({
            loading: false,
            error: data.error
          });
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message
        });
      });
    }
  }
}

  render(){
    return (
      <Modal width={400}>
        <h3>Lägg till en bil</h3>
        <p>Obligatoriska fält är markerade med<span className="red">*</span></p>
        <p className="modalError">{this.state.error}</p>
        <p>Registreringsnummer<span className="red">*</span></p>
        <input type="text" name="reg" maxLength={7} className="withLabel" value={this.state.reg} onChange={(event) => this.onChange({reg: event.target.value.toUpperCase()})}/>
        {
          this.state.hasCustomReg ?
          <div>
            <p>Äkta registreringsnummer<span className="red">*</span></p>
            <input type="text" name="realReg" maxLength={7} className="withLabel" value={this.state.realReg} onChange={(event) => this.onChange({realReg: event.target.value.toUpperCase()})}/>
          </div>
          : null
        }
        <Checkbox checked={this.state.hasCustomReg} label="Bilen har en personlig skylt" onClick={() => this.onChange({hasCustomReg: !this.state.hasCustomReg})}/>

        {
          this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService ?
            <div>
              <p>Datum för stripning<span className="red">*</span></p>
              <DatePicker
                selected={this.state.stripeServiceDate}
                onChange={(stripeServiceDate) => this.onChange({stripeServiceDate: stripeServiceDate})}
                onSelect={(stripeServiceDate) => this.onChange({stripeServiceDate: stripeServiceDate})}
                dateFormat="yyyy-MM-dd"
                minDate={moment().toDate()}
              />
            </div>
          :
          <div>
            <p>Förnamn</p>
            <input type="text" name="firstname" className="withLabel" value={this.state.firstname} onChange={(event) => this.onChange({firstname: event.target.value})}/>
            <p>Efternamn</p>
            <input type="text" name="lastname" className="withLabel" value={this.state.lastname} onChange={(event) => this.onChange({lastname: event.target.value})}/>
            <p>Mobilnummer</p>
            <input type="text" name="number" maxLength={15} className="withLabel" value={this.state.phone} onChange={(event) => this.onChange({phone: event.target.value.replace(new RegExp("[^0123456789+]", "g"),"").replace("+46", "0")})}/>
          </div>
        }
         
        {
          this.state.hasRegion ?
          (
            this.props.App.getAvailableInternalCleanVariants(this.state.region).length > 0 ?
            <div>
              <Checkbox checked={this.state.hasInternalClean} label="Invändig Städning" onClick={() => this.onChange({hasInternalClean: !this.state.hasInternalClean})}/>
              {
                this.state.hasInternalClean ?
                  <div>
                    <p>Abonnemangstyp för invändig städning:<span className="red">*</span></p>
                    <select id = "dropdown" value={this.state.internalCleanId} onChange={(event) => this.onChange({internalCleanId: event.target.value})}>
                      <option value="">Ej angett.</option>
                      {this.props.App.getAvailableInternalCleanVariants(this.state.region).map((variant) => <option key={variant.id} value={variant.id}>{truncateInternalCleanName(variant.name)} - {variant.price}kr/mån</option>)}
                    </select>
                  </div>
                : null
              }
            </div>
            : null
          )
          : null
        }

        <Button loading={this.state.loading} disabled={!this.state.submittable} text="Spara" onClick={() => this.submit()} style={{marginTop: 5}}/>
        <Button text="Avbryt" onClick={() => this.props.Dialogs.hide()} style={{marginTop: 5}}/>
      </Modal>
    );
  }
}

