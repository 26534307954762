/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Visar en laddningsikon, typ en halvcirkel, som roterar i all oändlighet.

  Props:
  -loading (bool)
  -color (string)
*/

import React, { Component } from 'react';

import "./../styles/LoadingSpinner.min.css";

export default class LoadingSpinner extends Component {
  render(){
    if(this.props.loading){
      return (
        <div className={this.props.color !== undefined ? "LoadingSpinner " + this.props.color : "LoadingSpinner"}>
          <div className="wrapper">
            <div className="spinner"/>
          </div>
        </div>
      );
    }
    else{
      return null;
    }
  }
}
