/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Används för att göra anrop till appens API.
*/

import GLOBALS from './GLOBALS.js';
var Buffer = require('buffer/').Buffer 

export default {
  fetch: function(f, p, abortSignal){
    return new Promise((resolve, reject) => {
      var sessionId = localStorage.getItem("sessionId");

      var params = "";
      var email = "";
      var password = "";
      var currentPassword = "";
      var newPassword = "";
      var token = "";
      if(p !== undefined && p !== null && p !== false){
        for(var key in p){
          switch (key) {
            case "email":
              email = p[key];
              break;
            case "password":
              password = p[key];
              break;
            case "currentPassword":
              currentPassword = p[key];
              break;
            case "newPassword":
              newPassword = p[key];
              break;
            case "token":
              token = p[key];
              break;
            default:
              if(typeof p[key] === "object"){
                params += "&" + key + "=" + encodeURIComponent(JSON.stringify(p[key]));
                break;
              }
              params += "&" + key + "=" + encodeURIComponent(p[key]);
              break;
              
          }
        }
      }

      // console.log("api.fetch f", f);
      // console.log("api.fetch p", p);
      // console.log("api.fetch params", params);

      var API_URL;
      if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
        API_URL = GLOBALS.API_URL.DEV;
        console.log("Fetching with dev API (" + API_URL + ")");
      }
      else{
        API_URL = GLOBALS.API_URL.PROD;
        console.log("Fetching with prod API (" + API_URL + ")");
      }

      var headers =  {
        'Accept': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/x-www-form-urlencoded'
      };
      switch (f) {
        case "login":
          headers['Authorization'] = 'Basic ' + Buffer.from(email + ":" + password).toString('base64');
          break;

        case "change-password":
          headers['Authorization'] = 'Basic ' + Buffer.from(currentPassword + ":" + newPassword).toString('base64');
          break;

        case "forgot-password":
          headers['Authorization'] = 'Basic ' + Buffer.from(email + ":").toString('base64');
          break;

        case "edit-account":
          headers['Authorization'] = 'Basic ' + Buffer.from(email + ":").toString('base64');
          break;

        case "reset-password":
          headers['X-Token'] = token
          headers['Authorization'] = 'Basic ' + Buffer.from(email + ":" + password).toString('base64');
          break;

        default:
          break;
      }

      if (sessionId !== null) {
        headers['X-Session-Id'] = sessionId;
      }
      console.log("sessionId:", sessionId)
      console.log("X-Session-Id:", headers['X-Session-Id'])

      fetch(API_URL, {
        method: "POST",
        headers: headers,
        body: "function=" + f + params,
        credentials: "include",
        signal: abortSignal
      })
      .then((response) => {
        var r = response.clone();

        response.json()
        .then((data) => {
          if(data.status === "LOGIN_EXPIRED"){
            console.log("api.fetch: " + f, data);
            this.fetch("logout");
            reject({
              message: "Sessionen har utgått. Vänligen logga in på nytt.",
              aborted: false,
              loginExpired: true
            });
          }
          else if(data.status === "ERROR" && data.fatal === true){
            data.error = "Ett fel har uppstått på vår server. Vänligen försök igen senare och kontakta oss om problemet kvarstår.";
            console.error("api.fetch (FATAL ERROR): " + f, data);
            resolve(data);
          }
          else{
            console.log("api.fetch: " + f, data);
            resolve(data);
          }
        })
        .catch((e) => {
          console.error("api response.json() error", e);
          r.text().then((t) => console.log("api.fetch raw text (" + f + ")", t));
          reject({
            message: "Någonting gick fel. Vänligen försök igen senare och kontakta systemets administratör om problemet kvarstår.",
            aborted: false,
            loginExpired: false
          });
        });
      })
      .catch((e) => {
        console.error("api.fetch error (" + f + ")", e.message);
        reject({
          message: "Någonting gick fel. Vänligen försök igen senare och kontakta systemets administratör om problemet kvarstår.",
          aborted: e.message === "The user aborted a request." ? true : false,
          loginExpired: false
        });
      });
    });
  }
};
