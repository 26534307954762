/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Används som grund för en dialogruta, är endast en tom dialogruta med en eventuell bakgrund.

  Props:
  -background (bool)
  -width (number)
*/

import React, { Component } from 'react';

import './../styles/Modal.min.css';

export default class Modal extends Component {
  componentDidMount(){
    document.body.classList.add("notScrollable");
  }

  componentWillUnmount(){
    document.body.classList.remove("notScrollable");
  }

  render(){
    return (
      <div className="Modal">
        {
          this.props.background !== false ?
          <div className="background"/>
          : null
        }
        <div className="modalBox" style={this.props.width === undefined ? null : {width: this.props.width}}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
