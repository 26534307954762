/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Visar en lista av nyinköpta bilar för det aktiva företaget. Visas endast om det finns några.

  Props:
  -App (App.js context)
*/

import React, { Component } from 'react';

import api from './../api.js';
import Images from './../Images.js';

import Card from './Card.js';
import Car from './Car.js';

export default class SuggestedCars extends Component {
  constructor(props){
    super(props);

    this.state = {
      fetching: false,
      error: null,
    };
  }

  componentDidMount(){
    this.props.App.SuggestedCars = this;

    if(!this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService){
      this.update();
    }
  }

  componentWillUnmount(){
    this.props.App.SuggestedCars = null;
  }

  update(){
    return new Promise((resolve, reject) => {
      this.fetch()
      .then((suggestedCars) => {
        this.props.App.setState({suggestedCars});
        resolve();
      })
      .catch((e) => {
        console.log(e);
        resolve();
      });
    });
  }

  fetch(){
    return new Promise((resolve, reject) => {
      this.setState({
        fetching: true
      });
      api.fetch("get-suggested-cars", {
        companyId: this.props.App.state.companies[this.props.App.state.activeCompanyIndex].id
      })
      .then((data) => {
        if(data.status === "OK"){
          this.setState({
            fetching: false
          });
          resolve(data.cars);
        }
        else{
          this.setState({
            fetching: false,
            error: data.error
          });
          reject(data.error);
        }
      })
      .catch((e) => {
        this.setState({
          fetching: false,
          error: e.message
        });
        reject(e.message);
      });
    });
  }

  info(){
    this.props.App.Dialogs.alert("Nyligen inköpta bilar", "Här listas bilar som förvärvats av företaget och uppdateras varje månad. Välj 'Aktivera' för att lägga till bilen i ert tvättavtal.");
  }

  render(){
    if(this.props.App.state.suggestedCars !== null && this.props.App.state.suggestedCars.length > 0 && this.state.error === null){
      return (
        <Card>
          <div className="cardSections">
            <div className="cardSection">
              <h2>Nyligen inköpta bilar</h2>
              <img className="icon clickable cardSectionTopRight" onClick={() => this.info()} src={Images.icons.InfoBlue} alt="Info"/>
              <p className="cardSectionTopRight" style={{textAlign: "right", right: 40}}>{this.props.App.state.suggestedCars.length} st</p>
              {
                this.props.App.state.suggestedCars.map((car, key) => <Car key={key} index={key} list={this.props.App.state.suggestedCars} car={car} App={this.props.App} suggested={true}/>)
              }
            </div>
          </div>
        </Card>
      );
    }
    else{
      return null;
    }
  }
}
