import React, { Component } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import "./styles/App.min.css";

import api from './api.js';
import GLOBALS from './GLOBALS.js';

import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Login from './components/Login.js';
import Card from './components/Card.js';
import Company from './components/Company.js';
import Account from './components/Account.js';
import RelatedCompanies from './components/RelatedCompanies.js';
import SuggestedCars from './components/SuggestedCars.js';
import Cars from './components/Cars.js';
import Dialogs from './components/Dialogs.js';
import PageButton from './components/PageButton.js';
import Updater from './components/Updater.js';
import ForceNewPassword from './components/ForceNewPassword.js';
import { gt } from 'semver';

export default class App extends Component {
  constructor(props){
    super(props);

    this.state = {
      loggedIn: false,
      updateAvailable: false,
      activeCompanyIndex: 0,
      companies: null,
      account: null,
      cars: null,
      suggestedCars: null,
      internalCleanVariants: [],
    };

    this.clientUpdateIntervalId = null;
    this.Dialogs = null;
    this.Cars = null;
    this.SuggestedCars = null;
    this.RelatedCompanies = null;

    this.checkForClientUpdate = this.checkForClientUpdate.bind(this);
  }

  componentDidMount(){
    this.checkForClientUpdate()
  }

  componentWillUnmount(){
  }

  logout(){
    api.fetch("logout")
    .then((data) => {
      localStorage.removeItem("sessionId");
      this.setState({
        loggedIn: false,
        activeCompanyIndex: 0,
        companies: null,
        account: null,
        cars: null
      });
    })
    .catch((e) => {
      //Någonting gick fel
    });
  }

  checkForClientUpdate(){
    api.fetch("get-latest-client-version")
    .then((data) => {
      if(data.status === "OK"){
        var currentVersion = GLOBALS.VERSION
        var availableVersion = data.version

        if (gt(availableVersion, currentVersion)) {
          this.setState({
            updateAvailable: true
          });
        }
      }
    });
  }

  editCompany(){
    this.Dialogs.show("edit-company", this.state.companies[this.state.activeCompanyIndex]);
  }

  editAccount(){
    this.Dialogs.show("edit-account", this.state.account);
  }

  changePassword(){
    this.Dialogs.show("change-password");
  }

  getAccount(){
    return new Promise((resolve, reject) => {
      api.fetch("get-account")
      .then((data) => {
        if(data.status === "OK"){
          resolve(data.account);
        }
        else{
          reject(data.error);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
    });
  }

  getCompanies(){
    return new Promise((resolve, reject) => {
      api.fetch("get-companies")
      .then((data) => {
        if(data.status === "OK"){
          resolve(data.companies);
        }
        else{
          reject(data.error);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
    });
  }

  getCars(){
    return api.fetch("get-cars", {companyId: this.state.companies[this.state.activeCompanyIndex]});
  }

  updateAll(){
    return new Promise((resolve, reject) => {
      this.updateAccount()
      .then(() => {
        this.updateCompanies()
        .then(() => {
          this.RelatedCompanies.update();
          this.Cars.update();
          this.SuggestedCars.update();
          resolve();
        })
        .catch((e) => {
          console.log(e);
          this.Dialogs.alert(e.message);
          reject();
        });
      })
      .catch((e) => {
        console.log(e);
        this.Dialogs.alert(e.message);
        reject();
      });
    });
  }

  updateAccount(){
    return new Promise((resolve, reject) => {
      this.getAccount()
      .then((account) => {
        this.setState({account}, () => resolve());
      })
      .catch((e) => {
        reject(e);
      });
    });
  }

  updateCompanies(){
    return new Promise((resolve, reject) => {
      this.getCompanies()
      .then((companies) => {
        this.setState({companies}, () => resolve());
      })
      .catch((e) => {
        reject(e);
      });
    });
  }

  getAvailableInternalCleanVariants(region) {
    var availableVariants = [];
    for (const variant of this.state.allInternalCleanVariants) {
      if (variant.regions.includes(region)) {
        availableVariants.push(variant);
      }
    }
    return availableVariants;
  }

  getInternalCleanVariantsMap() {
    let internalCleanVariantsMap = {};
    for (const variant of this.state.allInternalCleanVariants) {
      internalCleanVariantsMap[variant.id] = variant;
    }
    return internalCleanVariantsMap;
  }

  switchCompany(key){
    this.setState({activeCompanyIndex: key}, () => {
      this.RelatedCompanies.update();
      this.Cars.update();
    });
  }

  render(){
    if(this.state.updateAvailable){
      return (
        <Updater/>
      );
    }
    else if(this.state.loggedIn && this.state.account.temporaryPassword){
      return (
        <div id="App">
          <div id="content">
            <Header App={this} fixed={true}/>
            <ForceNewPassword App={this}/>
            <Footer App={this} fixed={true}/>
          </div>
        </div>
      );
    }
    else if(this.state.loggedIn){
      return (
        <div id="App" className="scrollable">
          <div id="content">
            <Header App={this}/>
            <RelatedCompanies App={this}/>
            <Card className="infoCard">
              <div className="cardSections">
                <Company App={this} company={this.state.companies[this.state.activeCompanyIndex]}/>
                <Account App={this} account={this.state.account}/>
              </div>
            </Card>
            <PageButton text="Lägg till bil" onClick={() => this.Dialogs.show("create-car")}/>
            <SuggestedCars App={this}/>
            <Cars App={this}/>
            <Dialogs App={this}/>
            <Footer App={this}/>
          </div>
        </div>
      );
    }
    else{
      return (
        <div id="App">
          <div id="content">
            <Header App={this} fixed={true}/>
            <Login App={this}/>
            <Footer App={this} fixed={true}/>
          </div>
        </div>
      );
    }
  }
}
