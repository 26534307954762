/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Denna komponent bygger upp en rad i ett kort. T.ex. för varje data som skall visas
  i kontots information används en sån här komponent. I denna komponents bör
  CardRowColumn användas

  Props:
  -dividerBottom (bool)
  -CardRowSub (bool)
  -edit (function)
  -delete (function)
*/

import React, { Component } from 'react';

import "./../styles/CardRow.min.css";

import Images from './../Images.js';

export default class CardRow extends Component {
  getClasses(dividerBottom, cardRowSub) {
    var classes = "CardRow";
    if (dividerBottom) {
      classes += " dividerBottom"
    }
    if (cardRowSub) {
      classes += " cardRowSub"
    }
    return classes
  }

  render(){
    return (
      <div className={this.getClasses(this.props.dividerBottom, this.props.cardRowSub)}>
        {this.props.children}
        {
          this.props.edit !== undefined?
          <img className="icon clickable" src={Images.icons.EditBlue} alt="Redigera" onClick={this.props.edit}/>
          : null
        }
        {
          this.props.delete !== undefined?
          <img className="icon clickable" src={Images.icons.DeleteRed} alt="Radera" onClick={this.props.delete}/>
          : null
        }
        {
          this.props.deactivate !== undefined?
          <img className="icon clickable" src={Images.icons.DeleteRed} alt="Avaktivera" onClick={this.props.deactivate}/>
          : null
        }
        {
          this.props.undoDeactivate !== undefined?
          <img className="icon clickable" src={Images.icons.UndoBlue} alt="Ångra" onClick={this.props.undoDeactivate}/>
          : null
        }
        {
          this.props.info !== undefined?
          <img className="icon clickable narrow" src={Images.icons.InfoBlue} alt="Info" onClick={this.props.info}/>
          : null
        }
      </div>
    );
  }
}
