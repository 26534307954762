/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Visar en dialogruta som inte går att undgå när en uppdatering för appen finns tillgänglig.
  Går inte att undgå då, om en uppdatering finns till appen så är det mycket möjligt
  att ändringar gjorts i API:et som gör sönder en gamla versioner av appen.

  Funktionen update laddar om hela sidan och rensar cache för filer.

  Inga props.
*/

import React, { Component } from 'react';

import Modal from './Modal.js';
import Button from './Button.js';

export default class Updater extends Component {
  constructor(props){
    super(props);

    this.state = {
      loading: false
    };
  }

  update(){
    this.setState({
      loading: true
    }, () => window.location.reload(true));
  }

  render(){
    return (
      <Modal background={false} width={300}>
        <p>En uppdatering finns tillgänglig. Vänligen uppdatera för att fortsätta använda BUSINESS®-admin.</p>
        <Button text="Uppdatera" loading={this.state.loading} onClick={() => this.update()} style={{marginTop: 5, display: "block"}}/>
      </Modal>
    );
  }
}
