/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  En dialogruta för att bekräfta radering av en bil på det aktiva företaget.

  Props:
  -Dialogs (Dialogs.js context)
  -App (App.js context)
  -car (object)
*/

import React, { Component } from 'react';
import moment from 'moment';

import api from '../../api.js';

import Modal from '../Modal.js';
import Button from '../Button.js';
import Checkbox from '../Checkbox.js';

export default class DeactivateCar extends Component {
  constructor(props){
    super(props);

    this.state = {
      submittable: false,
      noLongerNeedIt: false,
      notUsingItEnough: false,
      tooExpensive: false,
      notSatisfied: false,
      reason: "",
      change: false
    };
  }

  componentDidMount(){
    this.submittable();
  }

  submittable(){
    if(this.submittableReason() || this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService || this.props.car.deactivateOnDate !== ""){
      this.setState({
        submittable: true
      });
    }
    else{
      this.setState({
        submittable: false
      });
    }
  }

  submittableReason(){
    if(this.state.reason !== "" || this.state.noLongerNeedIt || this.state.notUsingItEnough || this.state.tooExpensive || this.state.notSatisfied || this.state.change){
      return true;
    }
    else {
      return false;
    }
  }

  onChange(val){
    this.setState(val, () => this.submittable());
  }

  deactivate(){
    if(this.state.submittable){
      this.setState({
        loading: true
      });

      api.fetch("deactivate-car", {
        id: this.props.car.id,
        noLongerNeedIt: this.state.noLongerNeedIt,
        notUsingItEnough: this.state.notUsingItEnough,
        tooExpensive: this.state.tooExpensive,
        notSatisfied: this.state.notSatisfied,
        reason: this.state.reason,
        change: this.state.change
      })
      .then((data) => {
        if(data.status === "OK"){
          this.props.App.Cars.update()
          .then(() => {
            this.props.Dialogs.hide();
            this.props.App.Cars.fetch();
            this.props.App.SuggestedCars.update();
          });
        }
        else{
          this.setState({
            loading: false,
            error: data.error
          });
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message
        });
      });
    }
  }

  render(){
    if(!this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService){
      if (this.props.car.deactivateOnDate === "") {
        return (
          <Modal width={400}>
            <h3>Vill du avsluta abonnemanget för {this.props.car.reg}?</h3>
            {
              this.state.error !== null ?
              <p className="modalError">{this.state.error}</p>
              : null
            }
            <p>Bilen kan tvättas till och med {this.props.App.state.companies[this.props.App.state.activeCompanyIndex].dateOfDeactivation !== undefined && this.props.App.state.companies[this.props.App.state.activeCompanyIndex].dateOfDeactivation !== null && this.props.App.state.companies[this.props.App.state.activeCompanyIndex].dateOfDeactivation !== "" ? moment(this.props.App.state.companies[this.props.App.state.activeCompanyIndex].dateOfDeactivation).format("yyyy-MM-DD") : ""}.</p>
            <p>Vi vill gärna veta varför du avslutar ditt abonnemang. {'\n'}Markera ett eller flera alternativ:</p>

            <Checkbox checked={this.state.noLongerNeedIt} label="Vi har inte längre behov av tjänsten." onClick={() => this.onChange({noLongerNeedIt: !this.state.noLongerNeedIt})}/>
            <Checkbox checked={this.state.notUsingItEnough} label="Vi använder inte tjänsten tillräckligt ofta." onClick={() => this.onChange({notUsingItEnough: !this.state.notUsingItEnough})}/>
            <Checkbox checked={this.state.tooExpensive} label="Vi tycker priset är för högt." onClick={() => this.onChange({tooExpensive: !this.state.tooExpensive})}/>
            <Checkbox checked={this.state.notSatisfied} label="Vi är inte nöjda med tjänsten." onClick={() => this.onChange({notSatisfied: !this.state.notSatisfied})}/>
            <textarea placeholder="Din kommentar.." type="text" name="reason" value={this.state.reason} onChange={(event) => this.onChange({reason: event.target.value})}/>

            <Button text="Avsluta abonnemang" color="red" loading={this.state.loading} disabled={!this.state.submittable} onClick={() => this.deactivate()} style={{marginTop: 5}}/>
            <Button text="Avbryt" onClick={() => this.props.Dialogs.hide()} style={{marginTop: 5}}/>
          </Modal>
        );
      } else {
        return (
          <Modal width={400}>
            <h3>Vill du återuppta abonnemanget för {this.props.car.reg}?</h3>
            {
              this.state.error !== null ?
              <p className="modalError">{this.state.error}</p>
              : null
            }

            <Button text="Återuppta abonnemang" color="red" loading={this.state.loading} disabled={!this.state.submittable} onClick={() => this.deactivate()} style={{marginTop: 5}}/>
            <Button text="Avbryt" onClick={() => this.props.Dialogs.hide()} style={{marginTop: 5}}/>
          </Modal>
        );
      }
    }
  }
}
