/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Dialogruta som visas när kontot har loggats in med ett tillfälligt lösenord.
  Tvingar användaren att byta lösenord för att kunna använda appen.

  Props:
  -App (App.js context)
*/

import React, { Component } from 'react';

import api from './../api.js';

import Modal from './Modal.js';
import Button from './Button.js';

export default class EditAccount extends Component {
  constructor(props){
    super(props);

    this.state = {
      error: null,
      loading: false,
      submittable: false,
      newPassword: "",
      newPasswordConfirm: "",
    };
  }

  submittable(){
    if(this.state.newPassword !== "" && this.state.newPasswordConfirm !== "" && this.state.newPassword === this.state.newPasswordConfirm){
      this.setState({
        submittable: true
      });
    }
    else{
      this.setState({
        submittable: false
      });
    }
  }

  onChange(val){
    this.setState(val, () => this.submittable());
  }

  submit(){
    if(this.state.submittable){
      this.setState({
        loading: true
      });

      api.fetch("change-password", {
        newPassword: this.state.newPassword
      })
      .then((data) => {
        if(data.status === "OK"){
          this.props.App.updateAccount()
          .catch(() => {
            window.location.reload();
          });
        }
        else{
          this.setState({
            loading: false,
            error: data.error
          });
        }
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          loading: false,
          error: "Någonting gick fel. Vänligen försök igen senare och kontakta oss om problemet kvarstår."
        });
      });
    }
  }

  render(){
    return (
      <Modal width={300} background={false}>
        <h3>Vänligen välj ett nytt lösenord</h3>
        {
          this.state.error !== null ?
          <p className="modalError">{this.state.error}</p>
          : null
        }

        <p>Nytt lösenord</p>
        <input className="withLabel" type="password" name="newPassword" value={this.state.newPassword} onChange={(event) => this.onChange({newPassword: event.target.value})}/>
        <p>Bekräfta nytt lösenord</p>
        <input className="withLabel" type="password" name="newPasswordConfirm" value={this.state.newPasswordConfirm} onChange={(event) => this.onChange({newPasswordConfirm: event.target.value})}/>

        <Button loading={this.state.loading} disabled={!this.state.submittable} text="Spara" onClick={() => this.submit()} style={{marginTop: 5}}/>
        <Button text="Avbryt" onClick={() => this.props.App.logout()} style={{marginTop: 5}}/>
      </Modal>
    );
  }
}
