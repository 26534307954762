/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  En dialogruta som tillåter användaren att ändra lösenord.

  Props:
  -Dialogs (Dialogs.js context)
  -App (App.js context)
*/

import React, { Component } from 'react';

import api from './../../api.js';

import Modal from './../Modal.js';
import Button from './../Button.js';

export default class EditAccount extends Component {
  constructor(props){
    super(props);

    this.state = {
      error: null,
      loading: false,
      submittable: false,
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    };
  }

  submittable(){
    if(this.state.currentPassword !== "" && this.state.newPassword !== "" && this.state.newPasswordConfirm !== "" && this.state.newPassword === this.state.newPasswordConfirm){
      this.setState({
        submittable: true
      });
    }
    else{
      this.setState({
        submittable: false
      });
    }
  }

  onChange(val){
    this.setState(val, () => this.submittable());
  }

  submit(){
    if(this.state.submittable){
      this.setState({
        loading: true
      });

      api.fetch("change-password", {
        currentPassword: this.state.currentPassword,
        newPassword: this.state.newPassword
      })
      .then((data) => {
        if(data.status === "OK"){
          this.props.App.updateAccount()
          .then(() => {
            this.props.App.Dialogs.hide();
            this.props.App.Dialogs.alert("Lösenordsbyte", "Ditt lösenord är nu bytt.");
          });
        }
        else{
          this.setState({
            loading: false,
            error: data.error
          });
        }
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          loading: false,
          error: "Någonting gick fel. Vänligen försök igen senare och kontakta oss om problemet kvarstår."
        });
      });
    }
  }

  render(){
    return (
      <Modal width={400}>
        <h3>Byt lösenord</h3>
        {
          this.state.error !== null ?
          <p className="modalError">{this.state.error}</p>
          : null
        }

        <p>Nuvarande lösenord</p>
        <input className="withLabel" type="password" name="currentPassword" value={this.state.currentPassword} onChange={(event) => this.onChange({currentPassword: event.target.value})}/>
        <p>Nytt lösenord</p>
        <input className="withLabel" type="password" name="newPassword" value={this.state.newPassword} onChange={(event) => this.onChange({newPassword: event.target.value})}/>
        <p>Bekräfta nytt lösenord</p>
        <input className="withLabel" type="password" name="newPasswordConfirm" value={this.state.newPasswordConfirm} onChange={(event) => this.onChange({newPasswordConfirm: event.target.value})}/>

        <Button loading={this.state.loading} disabled={!this.state.submittable} text="Spara" onClick={() => this.submit()} style={{marginTop: 5}}/>
        <Button text="Avbryt" onClick={() => this.props.Dialogs.hide()} style={{marginTop: 5}}/>
      </Modal>
    );
  }
}
