/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  En dialogruta för att bekräfta radering av en nyinköpt bil på det aktiva företaget.

  Props:
  -Dialogs (Dialogs.js context)
  -App (App.js context)
  -car (object)
*/

import React, { Component } from 'react';

import api from './../../api.js';

import Modal from './../Modal.js';
import Button from './../Button.js';

export default class DeleteSuggestedCar extends Component {
  constructor(props){
    super(props);

    this.state = {
      error: null
    };
  }

  delete(){
    this.setState({
      loading: true
    });

    api.fetch("delete-car", {
      id: this.props.car.id,
      reason: ""
    })
    .then((data) => {
      if(data.status === "OK"){
        this.props.App.SuggestedCars.update()
        .then(() => {
          this.props.Dialogs.hide();
          this.props.App.SuggestedCars.fetch();
        });
      }
      else{
        this.setState({
          loading: false,
          error: data.error
        });
      }
    })
    .catch((e) => {
      this.setState({
        loading: false,
        error: e.message
      });
    });
  }

  render(){
    return (
      <Modal width={400}>
        <h3>Radera {this.props.car.reg}</h3>
        <p>Är du säker på att du vill radera bilen?</p>

        {
          this.state.error !== null ?
          <p className="modalError">{this.state.error}</p>
          : null
        }

        <Button color="red" loading={this.state.loading} text="Radera" onClick={() => this.delete()} style={{marginTop: 5}}/>
        <Button text="Avbryt" onClick={() => this.props.Dialogs.hide()} style={{marginTop: 5}}/>
      </Modal>
    );
  }
}
