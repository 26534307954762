/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  En wrapper för en ensam knapp som tar upp hela appens bredd. Kan placeras mellan
  två kort för någon viktig funktion.

  Props:
  -text (string)
  -onClick (function)
*/

import React, { Component } from 'react';

import "./../styles/PageButton.min.css";

import Button from './Button.js';

export default class PageButton extends Component {
  render(){
    return (
      <div className="PageButton">
        <Button text={this.props.text} onClick={this.props.onClick}/>
      </div>
    );
  }
}
