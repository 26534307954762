/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Renderas i App.js och bestämmer vilken dialogruta som skall visas.

  Props:
  -App (App.js context)
*/

import React, { Component } from 'react';

import EditCompany from './dialogs/EditCompany.js';
import EditAccount from './dialogs/EditAccount.js';
import ChangePassword from './dialogs/ChangePassword.js';
import CreateCar from './dialogs/CreateCar.js';
import EditCar from './dialogs/EditCar.js';
import DeleteCar from './dialogs/DeleteCar.js';
import DeactivateCar from './dialogs/DeactivateCar.js';
import DeleteSuggestedCar from './dialogs/DeleteSuggestedCar.js';
import Alert from './dialogs/Alert.js';

export default class Dialogs extends Component {
  constructor(props){
    super(props);

    this.state = {
      show: false,
      name: null,
      params: null,
    };
  }

  componentDidMount(){
    this.props.App.Dialogs = this;
  }

  show(name, params){
    return new Promise((resolve, reject) => {
      this.setState({
        show: true,
        name: name,
        params: params !== undefined ? params : null
      }, () => resolve());
    });
  }

  hide(){
    return new Promise((resolve, reject) => {
      this.setState({
        show: false,
        name: null,
        params: null
      }, () => resolve());
    });
  }

  alert(title, message = null, buttons = null){
    this.show("alert", {title, message, buttons});
  }

  render(){
    if(this.state.name === "alert"){
      return (
        <Alert App={this.props.App} Dialogs={this} title={this.state.params.title} message={this.state.params.message} buttons={this.state.params.buttons}/>
      );
    }
    else if(this.state.name === "edit-company"){
      return (
        <EditCompany App={this.props.App} Dialogs={this} company={this.state.params}/>
      );
    }
    else if(this.state.name === "edit-account"){
      return (
        <EditAccount App={this.props.App} Dialogs={this} account={this.state.params}/>
      );
    }
    else if(this.state.name === "change-password"){
      return (
        <ChangePassword App={this.props.App} Dialogs={this}/>
      );
    }
    else if(this.state.name === "create-car"){
      return (
        <CreateCar App={this.props.App} Dialogs={this} car={this.state.params}/>
      );
    }
    else if(this.state.name === "edit-car"){
      return (
        <EditCar App={this.props.App} Dialogs={this} car={this.state.params}/>
      );
    }
    else if(this.state.name === "delete-car"){
      return (
        <DeleteCar App={this.props.App} Dialogs={this} car={this.state.params}/>
      );
    }
    else if(this.state.name === "deactivate-car"){
      return (
        <DeactivateCar App={this.props.App} Dialogs={this} car={this.state.params}/>
      );
    }
    else if(this.state.name === "activate-suggested-car"){
      return (
        <EditCar App={this.props.App} Dialogs={this} car={this.state.params} activateSuggested={true}/>
      );
    }
    else if(this.state.name === "delete-suggested-car"){
      return (
        <DeleteSuggestedCar App={this.props.App} Dialogs={this} car={this.state.params}/>
      );
    }
    else{
      return null;
    }
  }
}
