/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  En lista av "andra företag" som kontot har tillgång till.

  Props:
  -App (App.js context)
  -activeCompanyIndex (int)
*/

import React, { Component } from 'react';

import Card from './Card.js';
import RelatedCompany from './RelatedCompany.js';
import RelatedCompaniesColumnTitles from './RelatedCompaniesColumnTitles'

export default class OtherCompanies extends Component {
  constructor(props){
    super(props);

    this.state = {
      relatedCompanies: null,
      omittedIsLast: false,
    };
  }

  componentDidMount(){
    this.props.App.RelatedCompanies = this;
    this.update();
  }

  componentWillUnmount(){
    this.props.App.RelatedCompanies = null;
  }

  update(){
    var relatedCompanies = JSON.parse(JSON.stringify(this.props.App.state.companies));
    var omittedIsLast = false;

    if(this.props.activeCompanyIndex === relatedCompanies.length - 1){
      omittedIsLast = true;
    }

    relatedCompanies.splice(this.props.App.state.activeCompanyIndex, 1);

    this.setState({relatedCompanies, omittedIsLast});
  }

  info(){
    this.props.App.Dialogs.alert("Övriga kundnummer", "Här listas de övriga konton som du har behörighet att administrera. Välj 'Växla' för att skifta kundkonto.");
  }

  render(){
    if(this.state.relatedCompanies !== null && this.state.relatedCompanies.length !== null && this.state.relatedCompanies.length > 0){
      return (
        <Card>
          <div className="cardSections">
            <div className="cardSection companiesSection">
              <h2>Övriga kundnummer - {this.state.relatedCompanies.length} st</h2>
              <RelatedCompaniesColumnTitles App={this.props.App}/>
              {
                this.props.App.state.companies.map((company, key) => {
                  if(key !== this.props.App.state.activeCompanyIndex){
                    return (
                      <RelatedCompany key={key} index={key} shouldHaveDivider={this.state.omittedIsLast && key === this.state.relatedCompanies.length - 1 ? true : false} list={this.props.App.state.companies} company={company} App={this.props.App}/>
                    );
                  }
                  else{
                    return null;
                  }
                })
              }
            </div>
          </div>
        </Card>
      );
    }
    else{
      return null;
    }
  }
}
