/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Visar en dialogruta för att begära tillgång till appen. Visas när användaren navigerat
  till "Begär åtkomst" på inloggningssidan.

  Props:
  -Login (Login.js context)
*/

import React, { Component } from 'react';

import api from './../../api.js';

import Modal from './../Modal.js';
import Button from './../Button.js';

export default class RequestAccess extends Component {
  constructor(props){
    super(props);

    this.state = {
      loading: false,
      error: null,
      success: false,
      submittable: false,
      name: "",
      customerNumber: "",
      email: ""
    };
  }

  submit(){
    if(this.state.submittable){
      this.setState({
        loading: true
      });

      localStorage.removeItem("sessionId")
      api.fetch("request-access", {
        customerNumber: this.state.customerNumber,
        name: this.state.name,
        email: this.state.email
      })
      .then((data) => {
        if(data.status === "OK"){
          this.setState({
            loading: false,
            success: true,
          });
        }
        else{
          this.setState({
            loading: false,
            success: false,
            error: data.error
          });
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message
        });
      });
    }
  }

  submittable(){
    if(this.state.name !== "" && this.state.customerNumber !== "" && this.state.email !== "" && this.state.email.includes("@") && this.state.email.includes(".")){
      this.setState({
        submittable: true
      });
    }
    else{
      this.setState({
        submittable: false
      });
    }
  }

  onChange(value){
    this.setState(value, () => this.submittable());
  }

  render(){
    if(this.state.success){
      return (
        <Modal background={false} width={300}>
          <p>Vi har mottagit din förfrågan och återkommer till dig inom kort.</p>
          <Button text="Tillbaka" loading={this.state.loading} onClick={() => this.props.Login.navigate()} style={{marginTop: 5, display: "block"}}/>
        </Modal>
      );
    }
    else{
      return (
        <Modal background={false} width={300}>
          <h3>Begär åtkomst</h3>
          {
            this.state.error !== null ?
            <p className="modalError">{this.state.error}</p>
            : null
          }
          <p>Om flera kundnummer, separera med kommatecken</p>
          <input type="text" name="customerNumber" placeholder="Kundnummer" value={this.state.customerNumber} onChange={(event) => this.onChange({customerNumber: event.target.value})}/>
          <input type="text" name="name" placeholder="Namn" value={this.state.name} onChange={(event) => this.onChange({name: event.target.value})}/>
          <input type="email" name="email" placeholder="E-postadress" value={this.state.email} onChange={(event) => this.onChange({email: event.target.value})}/>
          <Button text="Begär åtkomst" disabled={!this.state.submittable} loading={this.state.loading} onClick={() => this.submit()} style={{marginTop: 5, display: "block"}}/>
          <div style={{textAlign: "center", marginTop: 5}}>
            <Button onClick={() => this.props.Login.navigate()} text="Tillbaka" textButton={true}/>
          </div>
        </Modal>
      );
    }
  }
}
