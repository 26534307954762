/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  En dialogruta för redigering av en bil på det aktiva företaget.

  Props:
  -Dialogs (Dialogs.js context)
  -App (App.js context)
  -car (object)
*/

import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import api from './../../api.js';

import Modal from './../Modal.js';
import Button from './../Button.js';
import Checkbox from './../Checkbox.js';
import {
  truncateInternalCleanName,
  asValidUUID,
  nonNullString
} from '../../utils.js';

import "./../../styles/Select.min.css";

export default class EditCar extends Component {
  constructor(props){
    super(props);

    this.state = {
      submittable: false,
      reg: nonNullString(this.props.car.reg),
      realReg: nonNullString(this.props.car.realReg),
      firstname: nonNullString(this.props.car.firstname),
      lastname: nonNullString(this.props.car.lastname),
      phone: nonNullString(this.props.car.phone),
      stripeServiceDate: this.props.car.stripeServiceDate !== null && this.props.car.stripeServiceDate !== "" ? new Date(this.props.car.stripeServiceDate) : "",
      hasCustomReg: nonNullString(this.props.car.realReg) !== "" ? true : false,
      hasInternalClean: this.props.car.internalCleanIncluded,
      internalCleanId: this.dropdownPreselectValue(),
      region: this.props.App.state.companies[this.props.App.state.activeCompanyIndex].region,
      hasRegion: false,
      internalCleanFreeTrial: this.props.car.internalCleanFreeTrial,
      dropdownSelected: false,
    };

    if (this.state.region !== "") {
      this.state.hasRegion = true;
    }
  }

  componentDidMount(){
    this.submittable()
  }

  submittable(){
    if(this.submittableValid() && this.submittableChanged()){
      this.setState({
        submittable: true
      });
    }
    else{
      this.setState({
        submittable: false
      });
    }
  }

  submittableValid() {
    return this.submittableValidReg() && this.submittableValidPersonal() && this.submittableValidInternalClean() && this.submittableValidStripeService()
  }
  
  submittableChanged() {
    return this.submittableChangedReg() || this.submittableChangedPersonal() || this.submittableChangedInternalClean() || this.submittableChangedStripeService()
  }

  submittableValidStripeService() {
    if (this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService) {
      return !(this.state.stripeServiceDate === "" || this.state.stripeServiceDate === null)
    } else  {
      return (this.state.stripeServiceDate === "" || this.state.stripeServiceDate === null)
    }
  }

  submittableChangedStripeService() {
    // information has changed
    if (this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService) {
      if (this.state.stripeServiceDate !== this.props.car.stripeServiceDate) {
        return true
      }
    }
    return false
  }

  submittableValidPersonal() {
    // no personal info is required
    return true
  }

  submittableChangedPersonal() {
    // check if information has changed
    if (this.state.firstname !== this.props.car.firstname) {
      return true
    }
    if (this.state.lastname !== this.props.car.lastname) {
      return true
    }
    if (this.state.phone !== this.props.car.phone) {
      return true
    }
    return false
  }

  submittableValidReg() {
    if (this.state.reg === "") {
      return false
    }
    if (this.state.hasCustomReg) {
      return this.state.realReg !== ""
    }
    return true
  }

  submittableChangedReg() {
    // reg has changed
    if (this.state.reg !== this.props.car.reg) {
      return true
    }

    // realReg has been removed
    if (!this.state.hasCustomReg && this.props.car.hasCustomReg) {
      return true
    }

    // realReg has been added
    if (this.state.hasCustomReg && !this.props.car.hasCustomReg) {
      return this.state.realReg !== ""
    }

    // realReg has changed
    if (this.state.hasCustomReg && this.props.car.hasCustomReg) {
      return this.state.realReg !== this.props.car.realReg
    }

    return false
  }

  submittableValidInternalClean() {
    if (this.state.hasInternalClean) {
      return this.state.internalCleanId !== ""
    }
    return true
  }

  submittableChangedInternalClean() {
    // If hasInternalClean is set for a car that has not previously had a internalCleanId set it will default to "Ej angett." (value = ""), this is not submittable.
    // This also catches "special" and "free" IC variants, as they don't have valid UUIDs.
    if (this.state.hasInternalClean && asValidUUID(this.state.internalCleanId)  === "") {
      return false
    }

    // If state of IC inluded has changed, but is not set while no variant is set, this is submittable.
    if (this.state.hasInternalClean !== this.props.car.internalCleanIncluded) {
      return true
    }

    // If IC wasn't, and still isn't, included this is not submittable.
    if (!this.state.hasInternalClean && !this.props.internalCleanIncluded) {
      return false
    }

    // If IC was included, and still is, and variantId has changed, this is submittable.
    if (this.state.hasInternalClean === this.props.car.internalCleanIncluded && asValidUUID(this.state.internalCleanId) !== this.props.car.internalCleanId) {
      return true
    }

    if (this.props.car.internalCleanId !== this.state.internalCleanId) {
      return true
    }

    return false
  }

  onChange(val){
    this.setState(val, () => this.submittable());
  }

 submitInternalCleanIncluded () {
    return (this.state.hasInternalClean && this.state.internalCleanId !== "" ? true : false);
  }

  submit(){
    if(this.state.submittable){
      this.setState({
        loading: true
      });

      var params = {
        id: this.props.car.id,
        companyId: this.props.App.state.companies[this.props.App.state.activeCompanyIndex].id,
        reg: this.state.reg,
        realReg: this.state.realReg,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        phone: this.state.phone,
        stripeServiceDate: "",
        internalCleanId: this.state.hasInternalClean ? asValidUUID(this.state.internalCleanId) : this.props.car.internalCleanId,
        internalCleanIncluded: this.submitInternalCleanIncluded(),
      };

      if(this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService){
        params.stripeServiceDate = moment(this.state.stripeServiceDate).format("yyyy-MM-DD");
      }

      api.fetch("edit-car", params)
      .then((data) => {
        if(data.status === "OK"){
          this.props.App.Cars.update()
          .then(() => {
            this.props.Dialogs.hide();
            this.props.App.SuggestedCars.update();

            if(typeof data.error === "string"){
              this.props.Dialogs.alert("Någonting gick fel", data.error);
            }
          });
        }
        else{
          this.setState({
            loading: false,
            error: data.error
          });
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message
        });
      });
    }
  }

  dropdownPreselectValue() {
    if (!this.props.car.internalCleanIncluded) {
      return ""
    }

    if (this.props.car.internalCleanIncluded && this.props.car.internalCleanFreeTrial) {
      return "free"
    }
    
    if (this.props.car.internalCleanIncluded && this.props.car.internalCleanId === "") {
      return "special"
    }
    
    return this.props.car.internalCleanId
  }

  render(){
    return (
      <Modal width={400}>
        {
          this.props.activateSuggested === true ?
          <h3>Lägg till {this.props.car.reg} till ert tvättavtal</h3>
          :
          <h3>Redigera {this.props.car.reg}</h3>
        }
        <p>Obligatoriska fält är markerade med<span className="red">*</span></p>
        <p className="modalError">{this.state.error}</p>
        <p>Registreringsnummer<span className="red">*</span></p>
        <input type="text" name="reg" maxLength={7} className="withLabel" value={this.state.reg} onChange={(event) => this.onChange({reg: event.target.value.toUpperCase()})}/>
        {
          this.state.hasCustomReg ?
          <div>
            <p>Äkta registreringsnummer<span className="red">*</span></p>
            <input type="text" name="realReg" maxLength={7} className="withLabel" value={this.state.realReg} onChange={(event) => this.onChange({realReg: event.target.value.toUpperCase()})}/>
          </div>
          : null
        }
        <Checkbox checked={this.state.hasCustomReg} label="Bilen har en personlig skylt" onClick={() => this.onChange({hasCustomReg: !this.state.hasCustomReg, realReg: !this.state.hasCustomReg === true ? nonNullString(this.props.car.realReg) : ""})}/>

        {
          this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService ?
            <div>
              <p>Datum för stripning <span className="red">*</span></p>
              <DatePicker
                selected={this.state.stripeServiceDate}
                onChange={(stripeServiceDate) => this.onChange({stripeServiceDate: stripeServiceDate})}
                onSelect={(stripeServiceDate) => this.onChange({stripeServiceDate: stripeServiceDate})}
                dateFormat="yyyy-MM-dd"
                minDate={moment().toDate()}
              />
            </div>
          :
          <div>
            <p>Förnamn</p>
            <input type="text" name="firstname" className="withLabel" value={this.state.firstname} onChange={(event) => this.onChange({firstname: event.target.value})}/>
            <p>Efternamn</p>
            <input type="text" name="lastname" className="withLabel" value={this.state.lastname} onChange={(event) => this.onChange({lastname: event.target.value})}/>
            <p>Mobilnummer</p>
            <input type="text" name="number" className="withLabel" value={this.state.phone} onChange={(event) => this.onChange({phone: event.target.value.replace(new RegExp("[^0123456789+]", "g"),"").replace("+46", "0")})}/>
          </div>
        }

        {
          this.state.hasRegion ?
          (
            this.props.App.getAvailableInternalCleanVariants(this.state.region).length > 0 ?
            <div>
              <Checkbox checked={this.state.hasInternalClean} label="Invändig Städning" onClick={() => this.onChange({hasInternalClean: !this.state.hasInternalClean})}/>
              {
                this.state.hasInternalClean ?
                  <div>
                    <p>Abonnemangstyp för invändig städning:<span className="red">*</span></p>
                    <select id = "dropdown" value={this.state.internalCleanId} onChange={(event) => this.onChange({internalCleanId: event.target.value})}>
                        <option value="">Ej angett.</option>
                      {
                        this.props.car.internalCleanFreeTrial ?
                        <option value="free">Gratis testperiod.</option>
                        : 
                          (
                            this.props.car.internalCleanId === "" && this.props.car.internalCleanIncluded ?
                            <option value="special">Special - {this.props.car.internalCleanPrice} kr/mån</option>
                            : null
                          )
                      }
                    {this.props.App.getAvailableInternalCleanVariants(this.state.region).map((variant) => <option key={variant.id} value={variant.id}>{truncateInternalCleanName(variant.name)} - {variant.price}kr/mån</option>)}
                    </select>
                  </div>
                : null
              }
            </div>
            : null
          )
          : null
        }

        <Button loading={this.state.loading} disabled={!this.state.submittable} text={this.props.activateSuggested === true ? "Aktivera" : "Spara"} onClick={() => this.submit()} style={{marginTop: 5}}/>
        <Button text="Avbryt" onClick={() => this.props.Dialogs.hide()} style={{marginTop: 5}}/>
      </Modal>
    );
  }
}
