/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Ett kort. Exporterar endast en vit div med lite fina definierade styles.

  Props:
  -style
*/

import React, { Component } from 'react';

import "./../styles/Card.min.css";

export default class Card extends Component {
  getClassName(className) {
    if (className) {
      return "Card " + className
    }
    return "Card"
  }

  render(){
    return (
      <div className={this.getClassName(this.props.className)} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}
