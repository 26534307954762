/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  En checkbox..? Vad mer vill du veta?

  Props:
  -className (string)
  -onClick (function)
  -label (string)
  -checked (bool)
*/

import React, { Component } from 'react';

import "./../styles/Checkbox.min.css";

export default class Checkbox extends Component {
  render(){
    return (
      <div className="CheckboxWrapper">
        <div className={"Checkbox " + (this.props.className !== undefined ? this.props.className : "")} onClick={this.props.onClick}>
          {
            this.props.checked ?
            <div className="filler"/>
            : null
          }
        </div>
        {
          this.props.label !== undefined ?
          <p>{this.props.label}</p>
          : null
        }
      </div>
    );
  }
}
