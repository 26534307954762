/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Listan av aktiva bilar.

  Props:
  -App (App.js context)
*/

import React, { Component } from 'react';
import moment from 'moment';

import download from 'downloadjs';
import Excel from 'exceljs/dist/exceljs';

import api from './../api.js';
import Images from './../Images.js';

import Card from './Card.js';
import LoadingSpinner from './LoadingSpinner.js';
import Car from './Car.js';
import CarsColumnTitles from './CarsColumnTitles.js';
import PageButton from './PageButton.js';
import { formatPhoneNumber, getFullName, truncateInternalCleanName} from '../utils.js';

export default class Cars extends Component {
  constructor(props){
    super(props);

    this.state = {
      fetching: false,
      error: null,
    };
  }

  componentDidMount(){
    this.props.App.Cars = this;

    this.update();
  }

  componentWillUnmount(){
    this.props.App.Cars = null;
  }

  update(){
    return new Promise((resolve, reject) => {
      this.fetch()
      .then((cars) => {
        this.props.App.setState({cars});
        resolve();
      })
      .catch((e) => {
        console.log(e);
        this.props.App.Dialogs.alert("Bilarna kunde inte hämtas", e);
        resolve();
      });
    });
  }

  fetch(){
    return new Promise((resolve, reject) => {
      this.setState({
        fetching: true
      });
      api.fetch("get-cars", {
        companyId: this.props.App.state.companies[this.props.App.state.activeCompanyIndex].id
      })
      .then((data) => {
        if(data.status === "OK"){
          this.setState({
            fetching: false
          });
          resolve(data.cars);
        }
        else{
          this.setState({
            fetching: false,
            error: data.error
          });
          reject(data.error);
        }
      })
      .catch((e) => {
        this.setState({
          fetching: false,
          error: e.message
        });
        reject(e.message);
      });
    });
  }

  info(){
    this.props.App.Dialogs.alert(this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService ? "Bilar" : "Aktiva bilar", "Här listas de bilar som är anslutna till ert tvättavtal. Dessa bilar faktureras enligt ert avtal.");
  }

  downloadExcelFile(){
    let workbook = new Excel.Workbook();
    workbook.creator = "Tvättcom (Mjuk Biltvätt Sverige AB)";
    workbook.created = new Date();
    workbook.modified = new Date();

    //TODO: stuff

    let worksheet = workbook.addWorksheet("Bilar");
    worksheet.columns = this.getExcelHeaders();
    worksheet.addRows(this.getCarRows());

    workbook.xlsx.writeBuffer()
      .then((buffer) => download(new Blob([buffer]), "Billista_" + this.props.App.state.companies[this.props.App.state.activeCompanyIndex].name + ".xlsx", "text/plain"));
  }

  getExcelHeaders(){
    let columns = [
      {header: "Reg.nr.", key: "reg"}
    ];
    if (this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService) {
      columns.push({header: "Datum för stripning, från", key: "stripeServiceDate", width: 25});
    } else {
      columns.push({header: "Förare", key: "fullName", width: 50});
      columns.push({header: "Telefon", key: "phone", width: 16});
      columns.push({header: "Invändig städning", key: "internalClean", width: 21});
      columns.push({header: "Datum avslut planerat", key:"deactivate", width:20})
    }

    return columns
  }

  getCarRows(){
    let stripeService = this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService
    let rows = [];
    let icvariantsMap = this.props.App.getInternalCleanVariantsMap();

    this.props.App.state.cars.forEach(function(car) {
      let row = {reg:car.reg};
      let deactivateOnDate = car.deactivateOnDate !== undefined && car.deactivateOnDate !== null && car.deactivateOnDate !== "" ? moment(car.deactivateOnDate).format("yyyy-MM-DD") : "";
      if (stripeService) {
        row['stripeServiceDate'] = (car.stripeServiceDate !== null && car.stripeServiceDate !== "" ? moment(car.stripeServiceDate).format("yyyy-MM-DD") : "");
      } else {
        row['fullName'] = getFullName(car.firstname, car.lastname);
        row['phone'] = formatPhoneNumber(car.phone);
        if (car.internalCleanIncluded) {
          if (car.internalCleanFreeTrial) {
            row["internalClean"] = "Gratis testperiod";
          } else if (car.internalCleanId === "") {
            row["internalClean"] = "Special";
          } else {
            var variant = icvariantsMap[car.internalCleanId];
            row["internalClean"] = truncateInternalCleanName(variant.name);
          }
        }
        if (deactivateOnDate !== "") {
          row['deactivate'] = deactivateOnDate;
        }
      }
      rows.push(row);
    });

    return rows
  }

  render(){
    if(this.props.App.state.cars !== null && this.state.error === null && !this.state.fetching){
      return (
        <Card>
          <div className="cardSections">
            <div className="cardSection carsSection">
              <h2>{this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService ? "Bilar" : ("Aktiva bilar - " + this.props.App.state.cars.length) + " st"}</h2>
              <CarsColumnTitles App={this.props.App}/>
              
              {
                this.props.App.state.cars.length > 0 ?
                this.props.App.state.cars.map((car, key) => <Car key={key} index={key} list={this.props.App.state.cars} car={car} App={this.props.App}/>)
                :
                <p>Inga bilar i avtal</p>
              }
              <PageButton text="Exportera till excel" onClick={() => this.downloadExcelFile()}/>
            </div>
          </div>
        </Card>
      );
    }
    else if (this.props.App.state.cars === null && this.state.error === null && !this.state.fetching) {
      return (
        <Card>
          <div className="cardSections">
            <div className="cardSection carsSection">
              <h2>{this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService ? "Bilar" : "Aktiva bilar"}</h2>
              <img className="icon clickable cardSectionTopRight" onClick={() => this.info()} src={Images.icons.InfoBlue} alt="Info"/>
              <p>Inga bilar i avtal.</p>
            </div>
          </div>
        </Card>
        );
    }
    else if(this.state.fetching){
      return (
        <Card>
          <div className="cardSections">
            <div className="cardSection carsSection">
              <h2>{this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService ? "Bilar" : "Aktiva bilar"}</h2>
              <img className="icon clickable cardSectionTopRight" onClick={() => this.info()} src={Images.icons.InfoBlue} alt="Info"/>
              <LoadingSpinner color="blue" loading={true}/>
            </div>
          </div>
        </Card>
      );
    }
    else {
      return (
        <Card>
          <div className="cardSections">
            <div className="cardSection carsSection">
              <h2>{this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService ? "Bilar" : "Aktiva bilar"}</h2>
              <img className="icon clickable cardSectionTopRight" onClick={() => this.info()} src={Images.icons.InfoBlue} alt="Info"/>
              <p>Någonting gick fel när bilarna skulle hämtas.</p>
            </div>
          </div>
        </Card>
      );
    }
  }
}
