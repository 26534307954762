/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Visar ett företag i listan av "andra företag".

  Props:
  -App (App.js context)
  -company (object)
  -index (int, key)
*/

import React, { Component } from 'react';

import CardRow from './CardRow.js';
import CardRowColumn from './CardRowColumn.js';
import Button from './Button.js';

export default class Car extends Component {
  info() {
    var alertString = "";
    alertString += "Kundnummer:\t" + this.props.company.customerNumber + "\n";
    alertString += "Region:\t\t" + this.props.company.region + "\n";
    alertString += "Leasingbolag:\t" + (this.props.company.leasingCompany ? this.props.company.leasingCompany : "Nej") + "\n";
    this.props.App.Dialogs.alert(this.props.company.name, (alertString));
  }

  render(){
    return (
      <CardRow dividerBottom={this.props.list.length !== this.props.index + 1 ? true : false} cardRowSub={true} info={() => this.info()}>
        <CardRowColumn wide={true} showTitle={false} title="Kundnummer" data={this.props.company.customerNumber} style={{maxWidth: 120}}/>
        <CardRowColumn showTitle={false} title="Företagsnamn" data={this.props.company.name}/>
        <CardRowColumn wide={true} showTitle={false} title="Region" data={this.props.company.region}/>
        <CardRowColumn wide={true} showTitle={false} title="Leasingbolag" data={this.props.company.leasingCompany}/>
        <CardRowColumn showTitle={false} title="" style={{textAlign: "right", maxWidth: 40}}>
          <Button textButton={true} text="Växla" onClick={() => this.props.App.switchCompany(this.props.index)}/>
        </CardRowColumn>
      </CardRow>
    );
  }
}
