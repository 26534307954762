/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Denna komponent bygger upp en kolumn i en kortrad (CardRow).

  Props:
  -dividerBottom (bool)
  -CardRowSub (bool)
  -title (string)
  -data (string)
  -style
  -internalClean (bool)
  -showTitle (bool)
  -noWrap (bool)
*/

import React, { Component } from 'react';

import "./../styles/CardRowColumn.min.css";

import Images from './../Images.js';

export default class CardRowColumn extends Component {
  getClasses(dividerBottom, internalClean, narrow, medium, wide, reg) {
    var classes = "CardRowColumn";
    if (dividerBottom) {
      classes += " dividerBottom"
    }
    if (internalClean) {
      classes += " internalClean"
    }
    if (narrow) {
      classes += " narrow"
    }
    if (medium) {
      classes += " medium"
    }
    if (wide) {
      classes += " wide"
    }
    if (reg) {
      classes += " reg"
    }
    return classes
  }

  getNowrap(){
    if (this.props.noWrap) {
      return "nowrap"
    }
    return ""
  }

  render(){
    var bottomName = "bottom";
    if (this.props.internalClean) {
      bottomName += " internal-clean-bottom";
    }

    return (
      <div className={this.getClasses(this.props.dividerBottom, this.props.internalClean, this.props.narrow, this.props.medium, this.props.wide, this.props.reg)} style={this.props.style}>
        {
          this.props.title !== "" && this.props.showTitle ?
          <div className="top">
            <div className="left">
              <p>{this.props.title}</p>
            </div>
            <div className="right"></div>
          </div>
          : null
        }

        <div className={bottomName}>
          {
            this.props.children === undefined ? 
           <p className={this.getNowrap()}>{this.props.data || "Ej angett" }</p>
            :
            this.props.children
          }
          
            {
              this.props.internalCleanInfo !== undefined && this.props.internalClean && this.props.data === "Ja" ?
              <div className="info-button">
                <img className="icon clickable" src={Images.icons.InfoBlue} alt="Info" onClick={this.props.internalCleanInfo}/>
                </div>
              : null
            }
        </div>
      </div>
    );
  }
}
