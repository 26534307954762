/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Denna komponent bygger upp en rad i ett kort. T.ex. för varje data som skall visas
  i kontots information används en sån här komponent. I denna komponents bör
  CardRowColumn användas

  Props:
  -dividerBottom (bool)
  -edit (function)
  -delete (function)
*/

import React, { Component } from 'react';

import "./../styles/CardRow.min.css";
import "./../styles/CardTitleRow.min.css";

import Images from './../Images.js';

export default class CardTitleRow extends Component {
  constructor(props){
    super(props);

    this.state = {
      buttons: this.buttons(this.props.buttons),
      narrowButtons: this.buttons(this.props.narrowButtons),
      wideButtons: this.buttons(this.props.wideButtons),
    };
  }

  buttons(i) {
    if (i === 0) {
      return null;
    }

    const buttons = [i-1];
    for (var k = i-1; k >= 0; k--) {
      buttons[k] = true;
    }
    return buttons;
  }

  render(){
    return (
      <div className="CardRow CardTitleRow dividerBottom">
        {this.props.children}
        {
          this.props.buttons !== 0 ?
            this.state.buttons.map((value, key) => <img key={key} index={key} className="icon clickable fauxButton" src={Images.icons.Empty} alt=""/>)
          : null
        }
        {
          this.props.narrowButtons !== 0 ?
            this.state.narrowButtons.map((value, key) => <img key={key} index={key} className="icon clickable fauxButton narrow" src={Images.icons.Empty} alt=""/>)
          : null
        }
        {
          this.props.wideButtons !== 0 ?
            this.state.wideButtons.map((value, key) => <img key={key} index={key} className="icon clickable fauxButton wide" src={Images.icons.Empty} alt=""/>)
          : null
        }
      </div>
    );
  }
}
