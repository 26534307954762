/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Visar en dialogruta för att byta sitt lösenord. Visas när användaren har följt
  instruktioner för att återställa sitt lösenord.

  Props:
  -Login (Login.js context)
  -token (string)
*/

import React, { Component } from 'react';

import api from './../../api.js';
import GLOBALS from './../../GLOBALS.js';

import Dialogs from './../../components/Dialogs.js';
import Modal from './../Modal.js';
import Button from './../Button.js';

export default class ResetPassword extends Component {
  constructor(props){
    super(props);

    this.state = {
      loading: false,
      error: null,
      submittable: false,
      email: "",
      password: "",
      passwordConfirm: "",
      token: this.props.token,
    };

    this.Dialogs = null
  }

  componentDidMount(){
    this.verifyToken();
  }

  verifyToken(){

  }

  submit(){
    this.setState({
      loading: true
    });

    localStorage.removeItem("sessionId")
    api.fetch("reset-password", {
      token: this.state.token,
      email: this.state.email,
      password: this.state.password
    })
    .then((data) => {
      if(data.status === "OK"){
        this.Dialogs.alert("Lösenordsbyte", "Lösenordsbytet lyckades.", [{text: "Ok", onClick: () => this.ref()}]);
      }
      else{
        this.setState({
          loading: false,
          error: data.error
        });
      }
    })
    .catch((e) => {
      this.setState({
        loading: false,
        error: e.message
      });
    });
  }

  ref() {
    window.location.href = GLOBALS.APP_URL;
  }


  submittable(){
    if(this.state.password !== "" && this.state.passwordConfirm !== "" && this.state.password === this.state.passwordConfirm && this.state.token !== "" && this.state.email !== "" && this.state.email.includes("@") && this.state.email.includes(".")){
      this.setState({
        submittable: true
      });
    }
    else{
      this.setState({
        submittable: false
      });
    }
  }

  onChange(value){
    this.setState(value, () => this.submittable());
  }

  render(){
    return (
      <Modal background={false} width={300}>
        {
          this.state.error !== null ?
          <p className="modalError">{this.state.error}</p>
          : null
        }
        <input type="text" name="token" placeholder="Återställningskod" value={this.state.token} onChange={(event) => this.onChange({token: event.target.value})}/>
        <input type="email" name="email" placeholder="E-postadress" value={this.state.email} onChange={(event) => this.onChange({email: event.target.value})}/>
        <input type="password" name="password" placeholder="Lösenord" value={this.state.password} onChange={(event) => this.onChange({password: event.target.value})}/>
        <input type="password" name="passwordConfirm" placeholder="Bekräfta lösenord" value={this.state.passwordConfirm} onChange={(event) => this.onChange({passwordConfirm: event.target.value})}/>
        <Button text="Byt lösenord" disabled={!this.state.submittable} loading={this.state.loading} onClick={() => this.submit()} style={{marginTop: 5, display: "block"}}/>
        <div style={{textAlign: "center", marginTop: 5}}>
          <Button onClick={() => this.props.Login.navigate()} text="Tillbaka" textButton={true}/>
        </div>
        <Dialogs App={this}/>
      </Modal>
    );
  }
}
