/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Knapp. Vad mer behöver sägas?

  Props:
  -loading (bool)
  -textButton (bool)
  -noMargin (bool)
  -color (string)
  -text (string)
  -style
*/

import React, { Component } from 'react';

import "./../styles/Button.min.css";

import LoadingSpinner from './LoadingSpinner.js';

export default class Button extends Component {
  render(){
    var classNames = "Button";

    if(this.props.textButton === true){
      classNames += " textButton";
    }

    if(this.props.noMargin === true){
      classNames += " noMargin";
    }

    if(this.props.color === "red"){
      classNames += " redBackground";
    }

    if(this.props.loading){
      return (
        <div className={classNames + " loading"} style={this.props.style}>
          <LoadingSpinner loading={true}/>
          <p style={{opacity: 0}}>{this.props.text}</p>
        </div>
      );
    }
    else if(this.props.disabled){
      return (
        <div className={classNames + " disabled"} style={this.props.style}>
          <p>{this.props.text}</p>
        </div>
      );
    }
    else{
      return (
        <div className={classNames} onClick={this.props.onClick} style={this.props.style}>
          <p>{this.props.text}</p>
        </div>
      );
    }
  }
}
