/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Denna fil används för funktioner releterade till URL. Alltså kan man
  med denna komponentens funktioner t.ex. hämta GET-parametrar.
*/

export default {
  get: function(param = null){
    var params = this.getAll();

    if(params[param] !== undefined){
      return params[param];
    }
    else{
      return false;
    }
  },
  getAll: function(){
    var url = window.location.href;

    if(url.includes("?")){
      var params = url.split("?")[1];
      params = params.split("&");

      if(typeof params == "string"){
        params = [params];
      }

      var obj = {};
      for(var key in params){
        if(params[key].includes("=")){
          var p = params[key].split("=");
          obj[p[0]] = p[1];
        }
        else{
          obj[params[key]] = null;
        }
      }

      return obj;
    }
    else{
      return {};
    }
  },
  isset: function(match){
    var params = this.getAll();

    if(params[match] !== undefined){
      return true;
    }
    else{
      return false;
    }
  }
};
