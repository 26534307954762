/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Denna komponent visar en bil i listorna av bilar.

  Props:
  -App (App.js context)
  -car (object)
  -suggested (bool)
  -list (array av bilar)
  -index (int, key)
*/

import React, { Component } from 'react';
import moment from 'moment';

import CardRow from './CardRow.js';
import CardRowColumn from './CardRowColumn.js';
import Button from './Button.js';
import {
  intToSweStr,
  formatPhoneNumber,
  boolToSweStr,
  nonNullString
} from '../utils.js';

export default class Car extends Component {
  edit(){
    this.props.App.Dialogs.show("edit-car", this.props.car);
  }

  delete(){
    this.props.App.Dialogs.show("delete-car", this.props.car);
  }

  deleteSuggested(){
    this.props.App.Dialogs.show("delete-suggested-car", this.props.car);
  }

  deactivate(){
    this.props.App.Dialogs.show("deactivate-car", this.props.car);
  }

  activateSuggested(){
    this.props.App.Dialogs.alert("activate-suggested-car", this.props.car);
  }

  internalCleanInfoString() {
    if (!this.props.car.internalCleanIncluded) {
      return "Ingår inte.";
    }
    

    if (this.props.car.internalCleanFreeTrial && this.props.car.internalCleanIncluded) {
      return "Denna bil har en gratis testperiod för invändig städning.";
    }

    var alertString = "";

    if (this.props.car.internalCleanIncluded) {
      if (this.props.car.internalCleanLimit === 0 || this.props.car.internalCleanLimitMonths === 0) {
        alertString += "Obegränsat antal invändiga städningar ingår.";
      } else {

        if (this.props.car.internalCleanLimit >= 1 &&  10 >= this.props.car.internalCleanLimit) {
          alertString += intToSweStr(this.props.car.internalCleanLimit, true, true, false)
        } else {
          alertString += this.props.car.internalCleanLimit;
        }

        if (this.props.car.internalCleanLimit === 1) {
          alertString += " invändig städning ";
        } else {
          alertString += " invändiga städningar ";
        }

        switch (this.props.car.internalCleanLimitMonths) {
          case 1:
            alertString += "varje månad";
            break;
          case 2:
            alertString += "varannan månad";
            break;
          case 3:
            alertString += "per kvartal";
            break;
          case 6:
            alertString += "per halvår";
            break;
          case 12:
            alertString += "per år";
            break;
        
          default:
            alertString += "varje ";
            alertString += intToSweStr(this.props.car.internalCleanLimitMonths, true, false, true)
            alertString += " månader"
            break;
        }
        alertString += " ingår.\n";
      }
    }return alertString;
  }

  internalCleanInfo(){
    this.props.App.Dialogs.alert("Invändig städning - " + this.props.car.reg, this.internalCleanInfoString());
  }

  info() {
    var alertString = "";
    if (this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService) {
      alertString += "Datum för stripning: " + (this.props.car.stripeServiceDate ? moment(this.props.car.stripeServiceDate).format("yyyy-MM-DD") : "Ej angett");
    } else {
      alertString += "Förare:\t\t\t\t" + (this.getFullName() ? this.getFullName() : "Ej angett") + "\n";
      alertString += "Mobilnummer:\t\t\t" + (this.props.car.phone ? formatPhoneNumber(nonNullString(this.props.car.phone)) : "Ej angett") + "\n";
      if (this.props.car.deactivateOnDate) {
        alertString += "Schemalagd för avslut:\t" + moment(this.props.car.deactivateOnDate).format("yyyy-MM-DD") + "\n";
      }
      if (this.props.car.businessClass !== "") {
        alertString += "Klass:\t\t\t\t" + this.props.car.businessClass + "\n";
      } else {
        alertString += "Klass:\t\t\t\tBUSINESS\n";
      }
      if (!this.props.car.internalCleanIncluded) {
        alertString += "Invändig städning:\t\t" + this.internalCleanInfoString() + "\n";
      } else {
        alertString += "Invändig städning:\n\t" + this.internalCleanInfoString() + "\n";
      }
    }
    this.props.App.Dialogs.alert(this.props.car.reg, (alertString));
  }

  getFullName() {
    var firstName = nonNullString(this.props.car.firstname)
    var lastName = nonNullString(this.props.car.lastname)
    if (firstName !== "" && lastName !== "") {
      return firstName + " " + lastName
    } else if (firstName !== "") {
      return firstName
    } else {
      return lastName
    }
  }

  render(){
    if(this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService){
      //Stripningstjänst
      return (
        <CardRow dividerBottom={this.props.list.length !== this.props.index + 1 ? true : false} edit={() => this.edit()} delete={() => this.delete()} cardRowSub={true} info={() => this.info()}>
          <CardRowColumn showTitle={false} title="Reg.nr." data={this.props.car.reg} reg={true}/>
          <CardRowColumn showTitle={false} wide={true} title="Datum för stripning" data={this.props.car.stripeServiceDate !== undefined && this.props.car.stripeServiceDate !== null && this.props.car.stripeServiceDate !== "" ? moment(this.props.car.stripeServiceDate).format("yyyy-MM-DD") : ""}/>
        </CardRow>
      );
    }
    else if(this.props.suggested === true){
      //Föreslagen bil
      return (
        <CardRow dividerBottom={this.props.list.length !== this.props.index + 1 ? true : false} delete={() => this.deleteSuggested()} cardRowSub={true}>
          <CardRowColumn showTitle={false} title="Reg.nr." data={this.props.car.reg} reg={true}/>
          <CardRowColumn showTitle={false} title="Förare" data={this.getFullName()}/>
          <CardRowColumn showTitle={false} title="Mobilnummer" data={formatPhoneNumber(nonNullString(this.props.car.phone))}/>
          <CardRowColumn style={{textAlign: "right"}}>
            <Button text="Aktivera" onClick={() => this.activateSuggested()} style={{height: 13}}/>
          </CardRowColumn>
        </CardRow>
      );
    }
    else{
      return (
        <CardRow dividerBottom={this.props.list.length !== this.props.index + 1 ? true : false} edit={() => this.edit()} {...(this.props.car.deactivateOnDate ? { undoDeactivate: () => this.deactivate() } : { deactivate: () => this.deactivate() })} info={() => this.info()} cardRowSub={true}>
          <CardRowColumn showTitle={false} title="Reg.nr." data={this.props.car.reg} reg={true}/>
          <CardRowColumn showTitle={false} wide={true} title="Förare" data={this.getFullName()}/>
          <CardRowColumn showTitle={false} wide={true} title="Mobilnummer" data={formatPhoneNumber(nonNullString(this.props.car.phone))}/>
          <CardRowColumn showTitle={false} wide={true} title="Klass" data={this.props.car.businessClass !== "" ? this.props.car.businessClass : "BUSINESS"}/>
          <CardRowColumn showTitle={false} wide={true} title="Invändig Städning" data={nonNullString(boolToSweStr(this.props.car.internalCleanIncluded))} internalCleanInfo={() => this.internalCleanInfo()} internalClean={this.props.car.internalCleanIncluded}/>
          <CardRowColumn showTitle={false} noWrap={true} title="Schemalagd för avaktivering" data={this.props.car.deactivateOnDate !== undefined && this.props.car.deactivateOnDate !== null && this.props.car.deactivateOnDate !== "" ? (<>Avslutas <wbr />{moment(this.props.car.deactivateOnDate).format("yyyy-MM-DD")}</>) : " "}/>
        </CardRow>
      );
    }
  }
}
