/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Footer.

  Props:
  -
*/

import React, { Component } from 'react';
import GLOBALS from './../GLOBALS.js';

import "./../styles/Footer.min.css";

export default class Footer extends Component {
  render(){
    return (
      <div id="Footer" className={this.props.fixed ? "fixed" : ""}>
        <p><a className="blue" href="#" onClick={() => this.props.App.logout()}>Logga ut</a>   <a className="blue" href="https://www.mjukbiltvatt.se/kontakt/">Kontakta oss</a></p>
        <p>Version {GLOBALS.VERSION}</p>
      </div>
    );
  }
}
