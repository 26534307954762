/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  En dialogruta för att bekräfta radering av en bil på det aktiva företaget.

  Props:
  -Dialogs (Dialogs.js context)
  -App (App.js context)
  -car (object)
*/

import React, { Component } from 'react';

import api from './../../api.js';

import Modal from './../Modal.js';
import Button from './../Button.js';
import Checkbox from './../Checkbox.js';

export default class DeleteCar extends Component {
  constructor(props){
    super(props);

    this.state = {
      submittable: false,
      noLongerNeedIt: false,
      notUsingItEnough: false,
      tooExpensive: false,
      notSatisfied: false,
      reason: "",
      change: false
    };
  }

  componentDidMount(){
    this.submittable();
  }

  submittable(){
    if(this.submittableReason() || this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService){
      this.setState({
        submittable: true
      });
    }
    else{
      this.setState({
        submittable: false
      });
    }
  }

  submittableReason(){
    if(this.state.reason !== "" || this.state.noLongerNeedIt || this.state.notUsingItEnough || this.state.tooExpensive || this.state.notSatisfied || this.state.change){
      return true;
    }
    else {
      return false;
    }
  }

  onChange(val){
    this.setState(val, () => this.submittable());
  }

  delete(){
    if(this.state.submittable){
      this.setState({
        loading: true
      });

      api.fetch("delete-car", {
        id: this.props.car.id,
        noLongerNeedIt: this.state.noLongerNeedIt,
        notUsingItEnough: this.state.notUsingItEnough,
        tooExpensive: this.state.tooExpensive,
        notSatisfied: this.state.notSatisfied,
        reason: this.state.reason,
        change: this.state.change
      })
      .then((data) => {
        if(data.status === "OK"){
          this.props.App.Cars.update()
          .then(() => {
            this.props.Dialogs.hide();
            this.props.App.Cars.fetch();
            this.props.App.SuggestedCars.update();
          });
        }
        else{
          this.setState({
            loading: false,
            error: data.error
          });
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message
        });
      });
    }
  }

  render(){
    if(this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService){
      //Stripningstjänst
      return (
        <Modal width={400}>
          <h3>Radera {this.props.car.reg}</h3>
          {
            this.state.error !== null ?
            <p className="modalError">{this.state.error}</p>
            : null
          }

          <p>Är du säker på att du vill ta bort bilen?</p>

          <Button color="red" loading={this.state.loading} text="Radera" onClick={() => this.delete()} style={{marginTop: 5}}/>
          <Button text="Avbryt" onClick={() => this.props.Dialogs.hide()} style={{marginTop: 5}}/>
        </Modal>
      );
    }
    else{
      return (
        <Modal width={400}>
          <h3>Radera {this.props.car.reg}</h3>
          {
            this.state.error !== null ?
            <p className="modalError">{this.state.error}</p>
            : null
          }

          <p>Vi vill gärna veta varför du avslutar ditt abonnemang. {'\n'} Markera ett eller flera alternativ:</p>

          <Checkbox checked={this.state.noLongerNeedIt} label="Jag har inte längre behov av tjänsten." onClick={() => this.onChange({noLongerNeedIt: !this.state.noLongerNeedIt})}/>
          <Checkbox checked={this.state.notUsingItEnough} label="Jag använder inte tjänsten tillräckligt ofta." onClick={() => this.onChange({notUsingItEnough: !this.state.notUsingItEnough})}/>
          <Checkbox checked={this.state.tooExpensive} label="Jag tycker priset är för högt." onClick={() => this.onChange({tooExpensive: !this.state.tooExpensive})}/>
          <Checkbox checked={this.state.notSatisfied} label="Jag är inte nöjd med tjänsten." onClick={() => this.onChange({notSatisfied: !this.state.notSatisfied})}/>
          <Checkbox checked={this.state.change} label="Flytt / ändring av bil." onClick={() => this.onChange({change: !this.state.change})}/>
          <textarea placeholder="Din kommentar.." type="text" name="reason" value={this.state.reason} onChange={(event) => this.onChange({reason: event.target.value})}/>

          <Button color="red" loading={this.state.loading} disabled={!this.state.submittable} text="Radera" onClick={() => this.delete()} style={{marginTop: 5}}/>
          <Button text="Avbryt" onClick={() => this.props.Dialogs.hide()} style={{marginTop: 5}}/>
        </Modal>
      );
    }
  }
}
