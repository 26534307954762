/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Importerar bilder och ikoner som enkelt kan användas i resten av appen genom att
  importera denna filen.
*/

import Logo from "./images/logo.png";

import Edit from './images/icons/edit.png';
import EditBlue from './images/icons/editBlue.png';
import Delete from './images/icons/delete.png';
import DeleteRed from './images/icons/deleteRed.png';
import Loading from './images/icons/loading.png';
import LoadingBlue from './images/icons/loadingBlue.png';
import LoadingWhite from './images/icons/loadingWhite.png';
import Info from './images/icons/info.png';
import InfoBlue from './images/icons/infoBlue.png';
import Empty from './images/icons/35x35_empty_icon.png';
import Undo from './images/icons/undo.png';
import UndoBlue from './images/icons/undoBlue.png';


export default {
  Logo,
  icons: {
    Edit,
    EditBlue,
    Delete,
    DeleteRed,
    Loading,
    LoadingBlue,
    LoadingWhite,
    Info,
    InfoBlue,
    Empty,
    Undo,
    UndoBlue,
  }
};
