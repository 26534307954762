import validate from 'uuid-validate';

export function truncateInternalCleanName(internalCleanName) {
    var index = internalCleanName.indexOf(" - ") + 3;
    var substring = internalCleanName.slice(index, internalCleanName.length);
    return substring;
}

// If the value of str is a valid uuid this function will return that same uuid, otherwise it'll return an empty string.
export function asValidUUID(str) {
    if (validate(str)) {
      return str;
    } else {
      return "";
    }
}

// Alt is a boolean specifying whether to use "en" (true) or "ett" (false).
// Capitalize is a boolean specifying whether the string should be capitalized or not.
// Eleven is a boolean specifying whether 11 should return "elva" (true) or 11 (false).
export function intToSweStr(i, alt=false, capitalize=false, eleven=false) {
    var returnString;
    switch (i) {
        case 1:
            returnString = alt ? "en" : "ett";
            break;
        case 2:
            returnString = "två";
            break;
        case 3:
            returnString = "tre";
            break;
        case 4:
            returnString = "fyra";
            break;
        case 5:
            returnString = "fem";
            break;
        case 6:
            returnString = "sex";
            break;
        case 7:
            returnString = "sju";
            break;
        case 8:
            returnString = "åtta";
            break;
        case 9:
            returnString = "nio";
            break;
        case 10:
            returnString = "tio";
            break;
        case 11:
            if (eleven) {
                returnString = "elva";
            } else {
                return i;
            }
            break;
        default:
            return i;
    }

    if (capitalize) {
        returnString = returnString.charAt(0).toUpperCase() + returnString.slice(1);
    }

    return returnString;
}

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatPhoneNumber(numberString) {
    if (numberString == null) {
        return null
    }

    if (numberString.length < 4) {
      return numberString;
    }

    var formattedString = numberString.slice(0, 3);
    formattedString += "-";
    numberString = numberString.slice(3, numberString.length);

    if (numberString.length < 4) {
      formattedString += numberString;
      return formattedString;
    }

    formattedString += numberString.slice(0, 3);
    formattedString += " ";
    numberString = numberString.slice(3, numberString.length);

    formattedString += repeatingPhoneFormat(numberString);

    return formattedString;
}

function repeatingPhoneFormat(numberString) {
    if (numberString < 3) {
      return numberString;
    }

    var formattedString = numberString.slice(0, 2);
    numberString = numberString.slice(2, numberString.length);

    return (formattedString + " " + repeatingPhoneFormat(numberString));
}

export function nonNullString(val) {
    if(val !== null){
        return val;
    } else {
        return "";
    }
}

export function boolToSweStr(boolValue) {
    if (boolValue) {
      return "Ja"
    } else {
      return "Nej"
    }
}

export function getFullName(firstname, lastname) {
    var firstName = nonNullString(firstname);
    var lastName = nonNullString(lastname);
    if (firstName !== "" && lastName !== "") {
      return firstName + " " + lastName;
    } else if (firstName !== "") {
      return firstName;
    } else {
      return lastName;
    }
  }

 export function padEnd(array, minLength, fillValue = undefined) {
    return Object.assign(new Array(minLength).fill(fillValue), array);
}