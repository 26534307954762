/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Dialogruta som visas när man är utloggad.

  Props:
  -App (App.js context)
*/

import React, { Component } from 'react';

import api from './../api.js';
import url from './../url.js';

import Modal from './Modal.js';
import Button from './Button.js';

import ForgotPassword from './Login/ForgotPassword.js';
import RequestAccess from './Login/RequestAccess.js';
import ResetPassword from './Login/ResetPassword.js';

export default class Login extends Component {
  constructor(props){
    super(props);

    this.state = {
      loading: true,
      error: null,
      pageIndex: "login",
      passwordResetToken: "",
      email: "",
      password: ""
    };
  }

  componentDidMount(){
    this.autoLogin()
    .catch(() => {
      if(url.isset("resetpassword")){
        var token = url.get("token");

        this.setState({
          pageIndex: "reset-password",
          passwordResetToken: typeof token === "string" ? token : ""
        });
      }
    });
  }

  autoLogin(){
    return new Promise((resolve, reject) => {
      api.fetch("auto-login")
      .then((data) => {
        if(data.status === "OK"){
          localStorage.setItem("sessionId", data.sessionId);
          if(data.companies.length > 0){
            this.props.App.setState({
              loggedIn: true,
              account: data.account,
              companies: data.companies,
              allInternalCleanVariants: data.internalCleanVariants,
            }, () => resolve());
          }
          else{
            this.setState({loading: false}, () => reject());
          }
        }
        else{
          localStorage.removeItem("sessionId")
          this.setState({
            loading: false
          }, () => reject());
        }
      })
      .catch((e) => this.setState({loading: false}, () => reject()));
    });
  }

  login(){
    if(this.state.email !== "" && this.state.password !== ""){
      this.setState({
        loading: true
      });

      api.fetch("login", this.state)
      .then((data) => {
        if(data.status === "OK"){
          localStorage.setItem("sessionId", data.sessionId);
          if(data.companies.length > 0){
            this.props.App.setState({
              loggedIn: true,
              account: data.account,
              companies: data.companies,
              allInternalCleanVariants: data.internalCleanVariants,
            });
          }
          else{
            this.setState({
              loading: false,
              error: "Inloggningsuppgifterna är korrekt men kontot har ingen tillgång till något företag."
            });
          }
        }
        else if(data.status === "ERROR"){
          localStorage.removeItem("sessionId")
          this.setState({
            loading: false,
            error: data.error
          });
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message
        });
      });
    }
  }

  navigate(pageIndex = null){
    this.setState({pageIndex: pageIndex === null ? "login" : pageIndex});
  }

  render(){
    if(this.state.pageIndex === "forgot-password"){
      return (
        <ForgotPassword App={this.props.App} Login={this}/>
      );
    }
    else if(this.state.pageIndex === "request-access"){
      return (
        <RequestAccess App={this.props.App} Login={this}/>
      );
    }
    else if(this.state.pageIndex === "reset-password"){
      return (
        <ResetPassword App={this.props.App} Login={this} token={this.state.passwordResetToken}/>
      );
    }
    else{
      return (
        <Modal background={false} width={300}>
          {
            this.state.error !== null ?
            <p className="modalError">{this.state.error}</p>
            : null
          }
          <input type="email" name="email" placeholder="E-postadress" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})}/>
          <input type="password" name="password" placeholder="Lösenord" value={this.state.password} onChange={(event) => this.setState({password: event.target.value})}/>
          <Button text="Logga in" loading={this.state.loading} onClick={() => this.login()} style={{marginTop: 5, display: "block"}}/>
          <div style={{textAlign: "center", marginTop: 5}}>
            <Button onClick={() => this.navigate("forgot-password")} text="Glömt lösenordet?" textButton={true}/>
            <p style={{display: "inline"}}>|</p>
            <Button onClick={() => this.navigate("request-access")} text="Begär åtkomst" textButton={true}/>
          </div>
        </Modal>
      );
    }
  }
}
