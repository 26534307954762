/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Visar det aktiva företagets information på appens startsida.

  Props:
  -company (object)
  -App (App.js context)
*/

import React, { Component } from 'react';

import Images from './../Images.js';

import CardRow from './CardRow.js';
import CardRowColumn from './CardRowColumn.js';

export default class Company extends Component {
  render(){
    return (
      <div className="cardSection companySection" style={{paddingRight: 25}}>
        <h2>{this.props.company.name}</h2>
        <img className="icon clickable cardSectionTopRight" onClick={() => this.props.App.editCompany()} src={Images.icons.EditBlue} alt="Redigera"/>

        <CardRow dividerBottom={true}>
          <CardRowColumn dividerBottom={true} showTitle={true} title="Kundnummer" data={this.props.company.customerNumber}/>
        </CardRow>
        <CardRow dividerBottom={true}>
          <CardRowColumn dividerBottom={true} showTitle={true} title="Leasingbolag" data={this.props.company.leasingCompany !== "" ? this.props.company.leasingCompany : "Ej angett"}/>
        </CardRow>
        <CardRow dividerBottom={true}>
          <CardRowColumn dividerBottom={true} showTitle={true} title="E-postadress för faktura" data={this.props.company.emailInvoice}/>
        </CardRow>
        <CardRow dividerBottom={true}>
          <CardRowColumn dividerBottom={true} showTitle={true} title="Besöksadress" data={this.props.company.address.visitingStreet}/>
        </CardRow>
        <CardRow>
          <CardRowColumn showTitle={true} title="Fakturaadress" data={this.props.company.address.street}/>
        </CardRow>
        <CardRow>
          <CardRowColumn showTitle={true} title="Postnummer" data={this.props.company.address.postalCode}/>
        </CardRow>
        <CardRow dividerBottom={true}>
          <CardRowColumn showTitle={true} dividerBottom={true} title="Ort" data={this.props.company.address.municipality}/>
        </CardRow>
        <CardRow dividerBottom={true}>
          <CardRowColumn showTitle={true} dividerBottom={true} title="Region" data={this.props.company.region}/>
        </CardRow>
        <CardRow dividerBottom={true}>
          <CardRowColumn showTitle={true} title="Fakturamärkning" data={this.props.company.invoiceMarker}/>
        </CardRow>
        <CardRow>
          <CardRowColumn showTitle={true} title="Tillåt förare att byta reg.nr" data={this.props.company.allowRegChangeOnMobile ? "Ja" : "Nej"}/>
        </CardRow>
      </div>
    );
  }
}
