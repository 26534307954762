/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  En dialogruta för redigering av användarens konto.

  Props:
  -Dialogs (Dialogs.js context)
  -App (App.js context)
  -account (object)
*/

import React, { Component } from 'react';

import api from './../../api.js';

import Modal from './../Modal.js';
import Button from './../Button.js';
import {
  nonNullString
} from '../../utils.js';

const initialState = {
  error: null,
  loading: false,
  submittable: false,
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
};

export default class EditAccount extends Component {
  constructor(props){
    super(props);

    this.state = initialState;
  }

  componentDidMount(){
    this.setState({
      firstname: nonNullString(this.props.account.firstname),
      lastname: nonNullString(this.props.account.lastname),
      email: nonNullString(this.props.account.email),
      phone: nonNullString(this.props.account.phone),
    }, () => this.submittable());
  }

  submittable(){
    if(this.state.firstname !== "" && this.state.email !== "" && this.state.phone !== "" && (this.state.firstname !== nonNullString(this.props.account.firstname) || this.state.lastname !== nonNullString(this.props.account.lastname) || this.state.email !== nonNullString(this.props.account.email) || this.state.phone !== nonNullString(this.props.account.phone))){
      this.setState({
        submittable: true
      });
    }
    else{
      this.setState({
        submittable: false
      });
    }
  }

  onChange(val){
    this.setState(val, () => this.submittable());
  }

  apply(){
    this.setState({
      loading: true
    });
    api.fetch("edit-account", {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone
    })
    .then((data) => {
      if(data.status === "OK"){
        this.props.App.updateAccount()
        .then(() => {
          this.props.App.Dialogs.hide();
        });
      }
      else{
        this.setState({
          loading: false,
          error: data.error
        });
      }
    })
    .catch((e) => {
      console.error(e);
      this.setState({
        loading: false,
        error: "Någonting gick fel. Vänligen försök igen senare och kontakta oss om problemet kvarstår."
      });
    });
  }

  submit(){
    if(this.state.submittable){
      if(this.state.email !== this.props.account.email){
        this.props.App.Dialogs.alert("Byte av e-postadress", "Du håller nu på att byta din e-postadress till '" + this.state.email + "'. Detta innebär att när du loggar in behöver du från och med nu använda denna. Vänligen kontrollera att den nya e-postadressen är korrekt innan du fortsätter.", [{text: "Fortsätt", onClick: () => this.apply()}, {text: "Avbryt", onClick: () => this.props.App.editAccount()}]);
      }
      else{
        this.apply();
      }
    }
  }

  render(){
    return (
      <Modal width={400}>
        <h3>Redigera mitt konto</h3>
        <p>Obligatoriska fält är markerade med<span className="red">*</span></p>
        <p className="modalError">{this.state.error}</p>
        <p>Förnamn<span className="red">*</span></p>
        <input className="withLabel" type="text" name="firstname" value={this.state.firstname} onChange={(event) => this.onChange({firstname: event.target.value})}/>
        <p>Efternamn</p>
        <input className="withLabel" type="text" name="lastname" value={this.state.lastname} onChange={(event) => this.onChange({lastname: event.target.value})}/>
        <p>E-postadress<span className="red">*</span></p>
        <input className="withLabel" type="email" name="email" value={this.state.email} onChange={(event) => this.onChange({email: event.target.value})}/>
        <p>Mobilnummer<span className="red">*</span></p>
        <input className="withLabel" type="text" name="phone" value={this.state.phone} onChange={(event) => this.onChange({phone: event.target.value})}/>

        <Button loading={this.state.loading} disabled={!this.state.submittable} text="Spara" onClick={() => this.submit()} style={{marginTop: 5}}/>
        <Button text="Avbryt" onClick={() => this.props.Dialogs.hide()} style={{marginTop: 5}}/>
        <Button text="Byt lösenord" onClick={() => this.props.App.changePassword()} style={{marginTop: 5}}/>
      </Modal>
    );
  }
}
