/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Används för konstanter som kan användas i hela appen
*/

var versioning = require("./versioning.json");

module.exports = {
  API_URL: {
    DEV: "http://localhost:3004/",
    PROD: "https://bus-adm-api.mjukbiltvatt.se/"
  },
  APP_URL: "https://businessadmin.mjukbiltvatt.se",
  VERSION: versioning.version,
  INITIAL_NAVIGATION_INDEX: "checklist",
  FAST_INTERVAL_TIME: 1000 * 60,
  SLOW_INTERVAL_TIME: 1000 * 60 * 3,
};
