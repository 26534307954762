/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Denna komponent visar en bil i listorna av bilar.

  Props:
  -App (App.js context)
  -car (object)
  -suggested (bool)
  -list (array av bilar)
  -index (int, key)
*/

import React, { Component } from 'react';

import CardTitleRow from './CardTitleRow.js';
import CardTitleRowColumn from './CardTitleRowColumn.js';
import Button from './Button.js';

export default class CarsColumnTitles extends Component {
  render(){
    if(this.props.App.state.companies[this.props.App.state.activeCompanyIndex].isStripeService){
      //Stripningstjänst
      return (
        <CardTitleRow buttons={2} narrowButtons={1} wideButtons={0}>
          <CardTitleRowColumn title="Reg.nr." reg={true}/>
          <CardTitleRowColumn title="Datum för stripning" wide={true}/>
        </CardTitleRow>
      );
    }
    else if(this.props.suggested === true){
      //Föreslagen bil
      return (
        <CardTitleRow buttons={1} narrowButtons={0} wideButtons={0}>
          <CardTitleRowColumn title="Reg.nr." reg={true}/>
          <CardTitleRowColumn title="Förare" wide={true}/>
          <CardTitleRowColumn title="Mobilnummer" wide={true}/>
          <CardTitleRowColumn style={{textAlign: "right"}}>
            <Button text="" style={{height: 13}}/>
          </CardTitleRowColumn>
        </CardTitleRow>
      );
    }
    else{
      return (
        <CardTitleRow buttons={2} narrowButtons={1} wideButtons={0}>
          <CardTitleRowColumn title="Reg.nr." reg={true}/>
          <CardTitleRowColumn title="Förare" wide={true}/>
          <CardTitleRowColumn title="Mobilnummer" wide={true}/>
          <CardTitleRowColumn title="Klass" wide={true}/>
          <CardTitleRowColumn title="Invändig Städning" wide={true}/>
          <CardTitleRowColumn title="" wide={true}/>
        </CardTitleRow>
      );
    }
  }
}
