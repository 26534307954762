/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Denna komponent bygger upp en kolumn i en kortrad (CardRow).

  Props:
  -dividerBottom (bool)
  -title (string)
  -data (string)
  -style
*/

import React, { Component } from 'react';

import "./../styles/CardRowColumn.min.css";
import "./../styles/CardTitleRowColumn.min.css";

export default class CardRowColumn extends Component {
  getClasses(narrow, wide, reg) {
    var classes = "CardRowColumn CardTitleRowColumn";
    if (narrow) {
      classes += " narrow"
    }
    if (wide) {
      classes += " wide"
    }
    if (reg) {
      classes += " reg"
    }
    return classes
  }

  render(){

    return (
      <div className={this.getClasses(this.props.narrow, this.props.wide, this.props.reg)} style={this.props.style}>
        <div className="top">
          <div className="left">
            <p>{this.props.title}</p>
          </div>
        </div>
      </div>
    );
  }
}
