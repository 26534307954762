/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Denna komponent visar kontots information på appens startsida.

  Props:
  -App (App.js context)
  -account (object)
*/

import React, { Component } from 'react';

import Images from './../Images.js';

import CardRow from './CardRow.js';
import CardRowColumn from './CardRowColumn.js';
import {
  formatPhoneNumber
} from '../utils.js';

export default class Account extends Component {
  render(){
    return (
      <div className="cardSection dividerLeft accountSection" style={{paddingLeft: 25}}>
        <h2>Mitt konto</h2>
        <img className="icon clickable cardSectionTopRight" onClick={() => this.props.App.editAccount()} src={Images.icons.EditBlue} alt="Redigera"/>

        <CardRow dividerBottom={true}>
          <CardRowColumn dividerBottom={true} showTitle={true} title="Namn" data={this.props.account.firstname + " " + this.props.account.lastname}/>
        </CardRow>
        <CardRow dividerBottom={true} title="E-postadress" data={this.props.account.email}>
          <CardRowColumn dividerBottom={true} showTitle={true} title="E-postadress" data={this.props.account.email}/>
        </CardRow>
        <CardRow title="Mobilnummer" data={formatPhoneNumber(this.props.account.phone)}>
          <CardRowColumn showTitle={true} title="Mobilnummer" data={formatPhoneNumber(this.props.account.phone)}/>
        </CardRow>
      </div>
    );
  }
}
