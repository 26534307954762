/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Denna komponent visar en bil i listorna av bilar.

  Props:
  -App (App.js context)
  -car (object)
  -suggested (bool)
  -list (array av bilar)
  -index (int, key)
*/

import React, { Component } from 'react';

import CardTitleRow from './CardTitleRow.js';
import CardTitleRowColumn from './CardTitleRowColumn.js';

export default class RelatedCompaniesColumnTitles extends Component {
  render(){
      return (
        <CardTitleRow buttons={0} narrowButtons={1} wideButtons={0}>
          <CardTitleRowColumn title="Kundnummer" wide={true} style={{maxWidth: 120}}/>
          <CardTitleRowColumn title="Företagsnamn"/>
          <CardTitleRowColumn title="Region" wide={true}/>
          <CardTitleRowColumn title="Leasingbolag" wide={true}/>
          <CardTitleRowColumn title="" style={{textAlign: "right", maxWidth: 40}}/>
        </CardTitleRow>
      );
  }
}
