/*
  Skapad av:
  Johannes Nyman

  Beskrivning:
  Dialogruta som visas när användaren har navigerat till "Glömt lösenord?" på inloggningssidan.

  Props:
  -Login (Login.js context)
*/

import React, { Component } from 'react';

import api from './../../api.js';

import Modal from './../Modal.js';
import Button from './../Button.js';

export default class ForgotPassword extends Component {
  constructor(props){
    super(props);

    this.state = {
      loading: false,
      success: false,
      error: null,
      email: "",
      password: ""
    };
  }

  submit(){
    this.setState({
      loading: true
    });

    localStorage.removeItem("sessionId")
    api.fetch("forgot-password", {email: this.state.email})
    .then((data) => {
      if(data.status === "OK"){
        this.setState({
          loading: false,
          success: true
        });
      }
      else{
        this.setState({
          loading: false,
          error: data.error
        });
      }
    })
    .catch((e) => {
      this.setState({
        loading: false,
        error: e.message
      });
    });
  }

  render(){
    if(this.state.success){
      return (
        <Modal background={false} width={300}>
          <p>Om den angivna e-postadressen matchar ett konto skickas inom kort instruktioner för att byta lösenordet.</p>
          <Button text="Tillbaka" loading={this.state.loading} onClick={() => this.props.Login.navigate()} style={{marginTop: 5, display: "block"}}/>
        </Modal>
      );
    }
    else{
      return (
        <Modal background={false} width={300}>
          <h3>Återställ lösenord</h3>
          {
            this.state.error !== null ?
            <p className="modalError">{this.state.error}</p>
            : null
          }
          <input type="email" name="email" placeholder="E-postadress" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})}/>
          <Button text="Begär nytt lösenord" disabled={this.state.email === "" || !this.state.email.includes("@") || !this.state.email.includes(".")} loading={this.state.loading} onClick={() => this.submit()} style={{marginTop: 5, display: "block"}}/>
          <div style={{textAlign: "center", marginTop: 5}}>
            <Button onClick={() => this.props.Login.navigate()} text="Tillbaka" textButton={true}/>
          </div>
        </Modal>
      );
    }
  }
}
